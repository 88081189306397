import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { epochTimeDifferenceInText } from 'utils/utils'
import { getStoryCorrections, setCorrections, setCurrentCorrection } from '../../redux/actions/dashboardAction'
import InfiniteScroll from 'react-infinite-scroll-component'

const Sidebar: React.FC = (props: any) => {
  const perPage = 10
  const [page, setPage] = useState(1)
  const [totalCorrection, setTotalCorrection] = useState(0)
  const [searchString, setSearchString] = useState('')

  useEffect(() => {
    if (props.corrections.length && !props?.currentStoryCorrection) props.setCurrentCorrection(props.corrections[0])
  }, [props.corrections])

  const setCorrection = (correction) => {
    props.setCurrentCorrection(correction)
  }

  const fetchData = async (isSearch) => {
    const currentCorrections = !isSearch ? [...props.corrections] : []
    const currentPage = !isSearch ? page : 1
    if (isSearch) setPage(1)
    const newCorrections = await props.getStoryCorrections(currentPage, perPage, searchString) //.then((res) => {
    setTotalCorrection(newCorrections.count)
    const allCorrections = currentCorrections.concat([...newCorrections.answers])
    props.setCorrections(allCorrections)

    if (allCorrections.length < newCorrections.count) setPage(page + 1)
  }

  const onTextChange = (e) => {
    setSearchString(e.target.value)
  }

  const clearSearch = () => {
    setSearchString('')
  }

  useEffect(() => {
    if (!searchString.length) {
      fetchData(true)
    } else
      setTimeout(() => {
        if (searchString.length > 2) {
          fetchData(true)
        }
      }, 1500)
  }, [searchString])

  return (
    <>
      <div className='left-side'>
        <div className='sidebar-logo mb-10 mt-10 pl-10'>
          <span className='color-white'>
            <b>maayot</b>
          </span>
        </div>

        <div className='searchbar-div mt-10 mb-10 text-lightgray'>
          <i className='fa fa-search searchicon'></i>
          <input
            type='text'
            placeholder='Search Assignment'
            className='search'
            value={searchString}
            onChange={onTextChange}
          />
          {searchString !== '' ? <i className='fa fa-times clear-search-icon m-pointer' onClick={clearSearch}></i> : ''}
        </div>

        <div className='dashboard-sidebar' id='scrollableDiv'>
          <InfiniteScroll
            dataLength={props.corrections.length}
            next={() => fetchData(false)}
            hasMore={props.corrections.length < totalCorrection}
            scrollableTarget='scrollableDiv'
            loader={
              <div className='assignment-main-box'>
                <div className='assignment-box w-d-flex align-items-center'>
                  <div className='user-content'>
                    <h5 className='color-white'>Loading...</h5>
                  </div>
                </div>
              </div>
            }
          >
            {props.corrections?.map((correction, index) => (
              <div
                key={index}
                className={`assignment-main-box
                  ${
                    `${correction?.data?.memberId} + ${correction?.data?.storyId}` ===
                    `${props?.currentStoryCorrection?.data?.memberId} + ${props?.currentStoryCorrection?.data?.storyId}`
                      ? correction.data.isOpen
                        ? 'activate-green'
                        : 'activate-orange'
                      : ''
                  }`}
                onClick={() => setCorrection(correction)}
              >
                <div className='assignment-box w-d-flex align-items-center'>
                  <div className='user-content'>
                    <span
                      className={
                        correction.data.isOpen
                          ? 'text-uppercase green-active active-box'
                          : 'text-uppercase orange-active active-box'
                      }
                    >
                      {correction.data.isOpen ? 'Open' : 'CLOSED'}
                    </span>
                    <div className='user-title color-white'>{correction.data.memberName}</div>
                    <span className='user-activate-time'>
                      {epochTimeDifferenceInText(correction?.data?.createdAt)} ago{' '}
                      <span className='dot text-capitalize'>{correction.data.level}</span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  corrections: state.dashboard.corrections,
  currentStoryCorrection: state.dashboard.currentStoryCorrection,
})

const mapActionsToProps = {
  getStoryCorrections,
  setCorrections,
  setCurrentCorrection,
}
export default connect(mapStateToProps, mapActionsToProps)(Sidebar)
