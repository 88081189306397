import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import {
  getStory,
  getStoryRecording,
  setStep,
  saveLastStep,
  setPreventRedirect,
  setShowRecordingSuccess,
  translate,
  wordDoesNotKnow,
  wordDoKnow,
} from '../../redux/actions/storyAction'
import { converterType, MemberStepInStory, storyStep, wordType } from '../../shared/constant'
import NavigationFooter from '../../containers/navigation-footer/NavigationFooter'
import { INTRODUCTION, QUIZ } from 'paths'
import Loader from '../../containers/loader/loader'
import history from '../../browserHistory'
import { characterConst } from '../../shared/constant'
import StoryIntro from 'containers/storyIntro'
import Translate from 'containers/popup/Translate'
import StoryTitle from './StoryTitle'
import { AddWordToHackChinese, handleAddWordToSkritter } from 'redux/actions/userActions'
import { getQuiz, getGeneralResult } from '../../redux/actions/quizAction'
import WithRedirect from 'containers/WithRedirect'
import { REGEX } from 'utils/regex'

const eyeIcon = <i className='fa fa-eye' />
const eyeSlashIcon = <i className='fa fa-eye-slash' />

const Story: React.FC<any> = ({
  saveLastStep,
  storyId,
  lastStep,
  lastStepEnum,
  setPreventRedirect,
  isGetData,
  showRecordingSuccess,
  storyLoading,
  storyLevel,
  story,
  user_auth_token,
  skritter_token,
  credentials,
  quizDetail,
  errors,
  getQuiz,
  getStoryRecording,
}) => {
  const { memberId } = credentials
  const chineseWordFontSize = useSelector((state) => state?.['dashboard']?.chineseWordFontSize)
  const popupRef = useRef<HTMLInputElement>(null)
  const contentStoryRef = useRef(document.createElement('div'))
  const [showPinYin, setShowPinYin] = useState(false)
  const [allowRecording, setAllowRecording] = useState(false)
  const [transVisible, setTransVisible] = useState(false)
  const [transData, setTransData] = useState({})
  const [activeIndex, setActiveIndex] = useState('-1')
  const [storyWord, setStoryWord] = useState([...story?.storyTitleContent, ...story?.storyContent])

  useEffect(() => {
    document.addEventListener('scroll', onTranslateClose)
    document.addEventListener('click', onDocumentClick)
    return () => {
      document.removeEventListener('scroll', onTranslateClose)
      document.removeEventListener('click', onDocumentClick)
    }
  }, [contentStoryRef, story])

  const handleTranslate = (event) => {
    if (
      (event.type === 'mouseover' || event.type === 'click') &&
      event.target.innerText &&
      event.target.innerText.length > 0
    ) {
      trans(event.target.innerText, event.clientY, event.clientX)
    }
  }

  const handleClickWord = (event, word, key) => {
    handleTranslate(event)
    if (typeof word !== 'string' && !REGEX.specialChars.test(word?.word)) {
      const storyWords = storyWord
        .filter?.((wordInfo) => {
          if (wordInfo?.word.includes('<p>') || wordInfo?.word.includes('</p>') || wordInfo?.word.includes('</p><p>')) {
          } else {
            return wordInfo
          }
        })
        ?.filter((wordInfo: any) => wordInfo?.word !== word?.word)
      setStoryWord(storyWords)
      wordDoesNotKnow({ word: word?.word, memberId, storyId })
    }
    setActiveIndex(activeIndex === key ? `-1` : key)
    event.preventDefault()
    event.stopPropagation()
  }

  const setTranslate = (word: string, posTop: number, posLeft: number, data: any) => {
    setTransVisible(true)
    const newWord1 = !story?.newWord1
      ? ''
      : globalThis.characterPreference === characterConst.traditional
      ? converterType.converterTraditional(story?.newWord1)
      : converterType.converterSimplified(story?.newWord1)
    const newWord2 = !story?.newWord2
      ? ''
      : globalThis.characterPreference === characterConst.traditional
      ? converterType.converterTraditional(story?.newWord2)
      : converterType.converterSimplified(story?.newWord2)
    const isOldWord = story?.oldWords?.includes(word)

    const type =
      newWord1 === word || newWord2 === word ? wordType.NewWord : isOldWord ? wordType.OldWord : wordType.Word
    setTransData({
      type,
      top: posTop,
      left: posLeft,
      word,
      definition: data.pinyin,
      example1: data.english,
      example2: '',
      level: 'HSK 5',
    })
  }

  const trans = (word: string, posTop: number, posLeft: number) => {
    const allDataOp = [...story?.arContent, ...story?.storyTitleContent]
    if (/^[&.-”“，、；？@!#$%^&*()]+$/.test(word) || word.trim() === '。') {
      setTransVisible(false)
      return
    }
    const wordTranslate = allDataOp.filter(
      (iword) =>
        (globalThis.characterPreference === characterConst.traditional
          ? converterType.converterTraditional(iword.word)
          : converterType.converterSimplified(iword.word)) === word,
    )
    if (wordTranslate && wordTranslate.length > 0) {
      setTranslate(word, posTop, posLeft, wordTranslate[0])
      if (user_auth_token) {
        AddWordToHackChinese(wordTranslate[0], user_auth_token)
      }
      if (skritter_token) {
        handleAddWordToSkritter({
          word,
          translate: wordTranslate[0],
          title: story?.storyTitleContent,
          skritter_token: skritter_token,
        })
      }
    } else {
      translate(word)
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          if (data && data.length > 0) {
            setTranslate(word, posTop, posLeft, data[0])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const onTranslateClose = () => {
    setTransVisible(false)
  }
  const onDocumentClick = (e) => {
    if (!popupRef?.current?.contains(e.target)) {
      setTransVisible(false)
    }
  }

  useEffect(() => {
    if (JSON.stringify(story) === '{}') {
      getStory(storyId, storyLevel, memberId)
      if (!quizDetail?.quiz) {
        getQuiz(storyId, storyLevel, memberId).then((result) => {
          if (result.memberAnswer) {
            getGeneralResult(storyId)
          }
        })
      }
    }

    getRecording()
  }, [isGetData, storyId, storyLevel, memberId])

  useEffect(() => {
    window.scrollTo(0, 0)
    setStep(storyStep.Step1)
    // getRecording()
  }, [])

  useEffect(() => {
    if (showRecordingSuccess) {
      setTimeout(() => setShowRecordingSuccess(false), 3000)
    }
  }, [showRecordingSuccess])

  useEffect(() => {
    if (
      storyId &&
      storyLevel &&
      memberId &&
      lastStep !== MemberStepInStory.STORY_AND_LISTENING &&
      (lastStepEnum || lastStepEnum === 0) &&
      lastStepEnum < storyStep.Step1
    ) {
      saveLastStep(MemberStepInStory.STORY_AND_LISTENING, storyLevel, memberId, storyId)
    }
  }, [storyId, storyLevel, memberId])

  const getRecording = () => {
    // if (membershipPlan === membershipNameType.Pro && storyId && memberId) {
    if (storyId && memberId) {
      getStoryRecording(storyId, memberId)
        .then((res) => {
          setAllowRecording(!res?.record_exist)
        })
        .catch(() => setAllowRecording(false))
    }
  }

  const handleChangeChk = () => {
    setShowPinYin(!showPinYin)
  }

  const icon = useMemo(() => {
    return showPinYin ? eyeSlashIcon : eyeIcon
  }, [showPinYin])

  const audio = useMemo(() => story.audio, [story])
  const backOnClick = (e) => {
    setPreventRedirect(true)
    history.push(`${INTRODUCTION}`)
  }

  const onNext = () => {
    const uniqueItems = {}
    storyWord.forEach((item) => {
      uniqueItems[item.word] = item
    })
    const wordList = Object.values(uniqueItems)
      ?.filter((wordInfo: any) => !REGEX.specialChars.test(wordInfo?.word))
      .map((wordInfo: any) => wordInfo?.word)
    wordDoKnow({ wordList, storyId, memberId })
    history.push(`${QUIZ}/${storyId}`)
  }

  return (
    <>
      <div ms-membership='free' className='section-2'>
        <div className='section-story w-container'>
          {showRecordingSuccess ? (
            <div className='recording-submitted-popup'>
              <h6>Your recording has been submitted</h6>
            </div>
          ) : (
            ''
          )}
          <div className='w-row m-flx m-sp-bw align-center introduction-main-div'>
            <div className='w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-4  content-left pl-10'>
              <span
                style={{ fontSize: chineseWordFontSize?.storyTitle, lineHeight: '1.5', letterSpacing: '1.5px' }}
                className='introduction-title'
              >
                {story?.storyTitle &&
                  (globalThis.characterPreference === characterConst.traditional ? (
                    <StoryTitle
                      storyTitleContent={story?.storyTitleContent}
                      showPinYin={showPinYin}
                      story={story}
                      activeIndex={activeIndex}
                      handleClickWord={handleClickWord}
                    />
                  ) : (
                    <StoryTitle
                      storyTitleContent={story?.storyTitleContent}
                      showPinYin={showPinYin}
                      story={story}
                      activeIndex={activeIndex}
                      handleClickWord={handleClickWord}
                    />
                  ))}
              </span>
            </div>
            {JSON.stringify(story) !== '{}' && (
              <div className='w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-8  pinyin-button pr-10'>
                <div className='left-sec-div'>
                  <button
                    className='button-2 bg-light-gray w-button'
                    onClick={() => {
                      handleChangeChk()
                    }}
                  >
                    {icon}
                    {showPinYin ? ` Hide PinYin` : ` Show PinYin`}
                  </button>
                </div>
              </div>
            )}
          </div>
          {JSON.stringify(story) !== '{}' ? (
            <>
              {(errors === 'Error' || errors === null) && story && (
                <>
                  <div className='w-nav story-nav-content'>
                    {/* <a className="active">简体字</a>
              <a>繁体字。</a> */}
                  </div>
                  <div className='w-row section-story-content'>
                    <StoryIntro
                      story={story}
                      showPinYin={showPinYin}
                      handleClickWord={handleClickWord}
                      activeIndex={activeIndex}
                      chineseWordFontSize={chineseWordFontSize}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <React.Fragment>
              {storyLoading ? (
                <Loader />
              ) : (
                <div className='item-title' style={{ textAlign: 'center' }}>
                  No translation is found
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
      <>
        <NavigationFooter
          audio={audio}
          backOnclick={backOnClick}
          nextPath={`${QUIZ}/${storyId}`}
          nextText={'Next Step'}
          allowRecording={allowRecording}
          nextOnclick={onNext}
        />
      </>
      <Translate ref={popupRef} visible={transVisible} data={transData} onClose={onTranslateClose} />
    </>
  )
}

const mapStateToProps = (state: any, ownProps: any) => ({
  user_auth_token: state.user.user_auth_token,
  skritter_token: state.user.skritter_token,
  credentials: state.user.credentials,
  membershipPlan: state.user.membership.name,
  membership: state.user.membership,
  story: state.story.currentStory,
  storyLoading: state.story.storyLoading,
  errors: state.UI.errors,
  storyLevel: state.story.storyLevel,
  storyId: ownProps.match.params.id,
  lastStep: state.story.lastStep,
  lastStepEnum: state.story.lastStepEnum,
  showRecordingSuccess: state.story.showRecordingSuccess,
  isGetData:
    (state.user.credentials.level.length > 0 || state.user.credentials.customFields?.level.length > 0) &&
    !state.story.errors &&
    (JSON.stringify(state.story.currentStory) === '{}' || state.story.currentStory.id !== ownProps.match.params.id),
  quizDetail: state.quiz.quizDetail,
})

const mapActionsToProps = {
  getStory,
  getStoryRecording,
  setStep,
  saveLastStep,
  setPreventRedirect,
  setShowRecordingSuccess,
  getQuiz,
  getGeneralResult,
}
export default connect(mapStateToProps, mapActionsToProps)(WithRedirect(Story))
