import React from 'react'
import Word from './Word'
import { converterType, characterConst } from '../../shared/constant'
interface INewWordProps {
  story?: any
  handleClickWord?: any
  activeIndex?: any
}

const NewWord: React.FC<INewWordProps> = (props: any) => {
  const { story, handleClickWord, activeIndex } = props
  return (
    <div className='w-col w-col-5 content-new-word'>
      <div className='w-row'>
        <div className='w-col'>
          <div className='story-newword'>New Words</div>
        </div>
        <div className='w-col section-story-newword'>
          <div className='w-row'>
            <div className='w-col-new-word'>
              <div className='new-word'>
                {globalThis.characterPreference === characterConst.traditional
                  ? converterType.converterTraditional(story?.newWord1)
                  : converterType.converterSimplified(story?.newWord1)}
              </div>
              <div className='col-pinyin-word'>
                <div className='w-row pinyin-word'>
                  <span>{story?.pinyinWord1}</span>
                </div>
                <div className='w-row definition'>
                  <span>{story?.definition1}</span>
                </div>
                <div className='w-row level'>
                  <span>{story?.levelWord1}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='w-row newword-example'>
            <div className='newword-title-example'>Example :</div>
            <div className='newword-example'>
              <p>
                {story?.arWordSen11.map((word, index) => {
                  const isOldWord = story?.oldWords?.includes(word)
                  const isNewWord = story?.newWord1 === word || story?.newWord2 === word
                  return word === '。' ? (
                    <React.Fragment key={index}>
                      。<br />
                    </React.Fragment>
                  ) : (
                    <Word
                      key={index}
                      index={`R11${index}`}
                      isActive={`R11${index}` === activeIndex}
                      text={word}
                      isNewWord={isNewWord}
                      isOldWord={!isNewWord && isOldWord}
                      onClick={handleClickWord}
                    />
                  )
                })}
              </p>
              <p>
                {story?.arWordSen12.map((word, index) => {
                  const isOldWord = story?.oldWords?.includes(word)
                  const isNewWord = story?.newWord1 === word || story?.newWord2 === word
                  return word === '。' ? (
                    <React.Fragment key={index}>
                      。<br />
                    </React.Fragment>
                  ) : (
                    <Word
                      key={index}
                      index={`R12${index}`}
                      isActive={`R12${index}` === activeIndex}
                      text={word}
                      isNewWord={isNewWord}
                      isOldWord={!isNewWord && isOldWord}
                      onClick={handleClickWord}
                    />
                  )
                })}
              </p>
            </div>
          </div>
        </div>
        <div className='w-col section-story-newword'>
          <div className='w-row'>
            <div className='w-col-new-word'>
              <div className='new-word'>
                {globalThis.characterPreference === characterConst.traditional
                  ? converterType.converterTraditional(story?.newWord2)
                  : converterType.converterSimplified(story?.newWord2)}
              </div>
              <div className='col-pinyin-word'>
                <div className='w-row pinyin-word'>
                  <span>{story?.pinyinWord2}</span>
                </div>
                <div className='w-row definition'>
                  <span>{story?.definition2}</span>
                </div>
                <div className='w-row level'>
                  <span>{story?.levelWord2}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='w-row newword-example'>
            <div className='newword-title-example'>Example :</div>
            <div className='newword-example'>
              <p>
                {story?.arWordSen21.map((word, index) => {
                  const isOldWord = story?.oldWords?.includes(word)
                  const isNewWord = story?.newWord1 === word || story?.newWord2 === word
                  return word === '。' ? (
                    <React.Fragment key={index}>
                      。<br />
                    </React.Fragment>
                  ) : (
                    <Word
                      key={index}
                      index={`R21${index}`}
                      isActive={`R21${index}` === activeIndex}
                      text={word}
                      isNewWord={isNewWord}
                      isOldWord={!isNewWord && isOldWord}
                      onClick={handleClickWord}
                    />
                  )
                })}
              </p>
              <p>
                {story?.arWordSen22.map((word, index) => {
                  const isOldWord = story?.oldWords?.includes(word)
                  const isNewWord = story?.newWord1 === word || story?.newWord2 === word
                  return word === '。' ? (
                    <React.Fragment key={index}>
                      。<br />
                    </React.Fragment>
                  ) : (
                    <Word
                      key={index}
                      index={`R22${index}`}
                      isActive={`R22${index}` === activeIndex}
                      text={word}
                      isNewWord={isNewWord}
                      isOldWord={!isNewWord && isOldWord}
                      onClick={handleClickWord}
                    />
                  )
                })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewWord
