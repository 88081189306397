import React, { useEffect } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import HeaderOld from '../containers/header-old/HeaderOld'
import { membershipNameType, membershipType } from '../shared/constant'
import WithNav from '../containers/WithNav'
import { getProfile } from '../redux/actions/myAccountAction'
import { INTRODUCTION, NOT_FOUND } from 'paths'
import HeaderNew from 'components/header-new/header'
interface IMyRouteProps extends RouteProps {
  component: any
  authenticated: boolean
  status: membershipType
  membershipPlan: membershipNameType
  currentStep: number
  rest?: any
  showNav?: boolean
  showDashboard?: boolean
  showCorrections?: boolean
}
const PrivateRoute: React.FC<IMyRouteProps> = ({
  component: Component,
  authenticated,
  status,
  membershipPlan,
  showNav,
  showDashboard,
  getProfile,
  memberId,
  showCorrections,
  ...rest
}: any) => {
  const isTeacher = membershipPlan === membershipNameType.Teacher
  const isPro = membershipPlan === membershipNameType.Pro
  const isDashboard = showDashboard && isTeacher
  const isCorrections = showCorrections && isPro
  const { isMobNav }: any = useSelector<any>(({ dashboard }) => dashboard)

  useEffect(() => {
    if (authenticated && memberId) {
      getProfile(memberId)
    }
  }, [authenticated, memberId])

  return (
    <>
      {!isTeacher || (isTeacher && rest.path !== '/dashboard') ? <HeaderNew pathName={rest.path} /> : null}
      {/* {!isTeacher || (isTeacher && rest.path !== '/dashboard') ? <HeaderOld pathName={rest.path} /> : null} */}
      <Route
        {...rest}
        render={(props) =>
          authenticated ? (
            // Uncomment Below Line for Do not Access Introduction Page for Teacher User
            // (showNav && !isTeacher && (
            (showNav && (
              <div className={!isMobNav ? 'z_index_div common_page' : 'common_page'}>
                <div className='section_div scroll_contain_div'>
                  <WithNav step={rest.currentStep}>
                    <Component {...props} />
                  </WithNav>
                </div>
              </div>
              //props?.membershipPlan === membershipNameType.Pro
            )) ||
            (!showNav && !isTeacher && !isDashboard && rest.path !== '/correction' && (
              <div className='common_page'>
                <div className='section_div'>
                  <Component {...props} />
                </div>
              </div>
            )) ||
            (!showNav && isTeacher && rest.path !== '/correction' && (
              // <div className='common_page'>
              //   <div className='section_div'>
              <Component {...props} />
              // </div>
              // </div>
            )) ||
            (!showNav && isPro && (
              <div className='common_page'>
                <div className='section_div'>
                  <Component {...props} />
                </div>
              </div>
            )) || <Redirect to={NOT_FOUND} />
          ) : (
            <Redirect to='/login' />
          )
        }
      />
    </>
  )
}
const mapStateToProps = (state: any) => ({
  authenticated: state.user.authenticated,
  currentStep: state.story.currentStep,
  status: state.user.membership.status,
  membershipPlan: state.user?.membership?.name,
  memberId: state.user.credentials?.memberId || '',
})
const mapActionsToProps = {
  getProfile,
}
export default connect(mapStateToProps, mapActionsToProps)(PrivateRoute)
