import React, { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import ReactModal from 'react-modal'
import uuid from 'react-uuid'
import { customStyles } from '../account/styles'
import Loader from '../../containers/loader/loader'
import { setShowRecordingSuccess, submitRecording, translate } from '../../redux/actions/storyAction'
import { uploadFileOnS3 } from 'utils/S3Upload'
import Recorder from 'containers/recorder/Recorder'
import { setAudioRecording } from 'redux/actions/recorderAction'
import { characterConst, converterType, wordType } from 'shared/constant'
import Story from './popupStory/Story'
import Translate from 'containers/popup/Translate'
import { AddWordToHackChinese, handleAddWordToSkritter } from 'redux/actions/userActions'

const StoryRecordingModal: React.FC = (props: any) => {
  const { isOpen, onCancel, storyId, storyLevel } = props
  const chineseWordFontSize = useSelector((state) => state?.['dashboard']?.chineseWordFontSize)

  const { memberId } = props.credentials
  const firstName = props.credentials.customFields?.['first-name'] || props.credentials.metaData?.firstName
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [firstLineStory, setFirstLineStory] = useState({})

  const contentStoryRef = useRef(document.createElement('div'))
  const [transVisible, setTransVisible] = useState(false)
  const [transData, setTransData] = useState({})
  const [activeIndex, setActiveIndex] = useState('-1')
  const popupRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    onReset()
  }, [isOpen])

  useEffect(() => {
    const firstLineWords: string[] = []
    let firstLineBreak = false

    props?.story?.storyContent?.forEach((wordObj) => {
      firstLineBreak =
        !firstLineBreak && (wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')) ? true : firstLineBreak

      if (!firstLineBreak) firstLineWords.push({ ...wordObj })

      return wordObj
    })
    setFirstLineStory({
      arContent: firstLineWords,
      storyContent: firstLineWords,
      newWord1: props?.story.newWord1,
      newWord2: props?.story.newWord2,
      oldWords: props?.story.oldWords,
      showPinYin: false,
    })
  }, [props.story.storyContent])

  const onRequestClose = () => {
    if (!isSubmitting) {
      onReset()
      onCancel()
    }
  }

  const onReset = async () => {
    setAudioRecording({})
    setIsSubmitting(false)
  }

  const onSubmitRecording = async () => {
    if (!isSubmitting) {
      onReset()
      setIsSubmitting(true)
      const uploadKey = `${memberId}/${storyId}-${uuid()}.mp3`
      const payload = { uploadKey, file: props.currentAudioRecording, contentType: props.currentAudioRecording?.type }
      try {
        const uploadResponse = await uploadFileOnS3(payload)
        props
          ?.submitRecording(storyId, memberId, firstName, uploadResponse.Key, storyLevel)
          .then(() => {
            onReset()
            props.setShowRecordingSuccess(true)
            onCancel(true)
          })
          .catch(() => setIsSubmitting(false))
      } catch (e) {
        setIsSubmitting(false)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', onTranslateClose)
    document.addEventListener('click', onDocumentClick)
    return () => {
      document.removeEventListener('scroll', onTranslateClose)
      document.removeEventListener('click', onDocumentClick)
    }
  }, [contentStoryRef, props.questionDetail])

  const setTranslate = (word: string, posTop: number, posLeft: number, data: any) => {
    setTransVisible(true)

    const type = props?.story?.oldWords?.includes(word)
      ? wordType.OldWord
      : props?.story?.newWord1 === word || props?.story?.newWord2 === word
      ? wordType.NewWord
      : wordType.Word
    setTransData({
      type,
      top: posTop,
      left: posLeft,
      word,
      definition: data.pinyin,
      example1: data?.english,
      example2: '',
      level: 'HSK 5',
    })
  }

  const handleTranslate = (event) => {
    if (
      (event.type === 'mouseover' || event.type === 'click') &&
      event.target.innerText &&
      event.target.innerText.length > 0
    ) {
      trans(event.target.innerText, event.clientY, event.clientX)
    }
  }

  const trans = (word: string, posTop: number, posLeft: number) => {
    if (/^[&.-”“，、；？@!#$%^&*()]+$/.test(word) || word.trim() === '。') {
      setTransVisible(false)
      return
    }
    const wordTranslate = props?.story?.arContent?.filter(
      (iword) =>
        (globalThis.characterPreference === characterConst.traditional
          ? converterType.converterTraditional(iword.word)
          : converterType.converterSimplified(iword.word)) === word,
    )

    if (wordTranslate && wordTranslate.length > 0) {
      setTranslate(word, posTop, posLeft, wordTranslate[0])
      if (props?.user_auth_token) {
        AddWordToHackChinese(wordTranslate[0], props?.user_auth_token)
      }
      if (props?.skritter_token) {
        handleAddWordToSkritter({
          word,
          translate: wordTranslate[0],
          title: props?.story?.storyTitleContent,
          skritter_token: props?.skritter_token,
        })
      }
    } else {
      translate(word)
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          if (data && data.length > 0) {
            setTranslate(word, posTop, posLeft, data[0])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const onTranslateClose = () => {
    setTransVisible(false)
  }
  const onDocumentClick = (e) => {
    if (!popupRef?.current?.contains(e.target)) {
      setTransVisible(false)
    }
  }

  const handleClickWord = (event, key) => {
    handleTranslate(event)
    setActiveIndex(activeIndex === key ? `-1` : key)
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles} ariaHideApp={false}>
      <div className=''>
        <div className='w-row m-flx m-sp-bw align-center'>
          <div className='w-col-12 m-al-l'>
            <div className='modal-header m-flx'>
              <h3> Read and record the following passage </h3>
              <div className='modal-close-btn'>
                <i className='fa fa-times' onClick={onRequestClose}></i>
              </div>
            </div>
          </div>
        </div>

        <div className='w-nav modal-body-content-line'> </div>

        <div className='w-row m-flx section-story'>
          <div className='w-col-12 w-col-medium-12 w-col-small-12 m-al-l'>
            <div
              style={{ fontSize: chineseWordFontSize?.storyContent, lineHeight: '1.5', letterSpacing: '1.5px' }}
              className='modal-body-content mb-30 story-content'
            >
              <Story story={firstLineStory} handleClickWord={handleClickWord} activeIndex={activeIndex} />
            </div>
            <Translate ref={popupRef} visible={transVisible} data={transData} onClose={onTranslateClose} />
          </div>
        </div>

        <div className='w-nav modal-body-content-line'> </div>

        <div className='mt-10'>
          <Recorder></Recorder>
          <div className='w-nav modal-body-content-line'> </div>
          <div className='w-row m-flx align-center'>
            <div className='cancel-btn pr-10'>
              <button className='button-2 bg-light-gray w-button' onClick={onRequestClose}>
                Cancel
              </button>
            </div>
            <div className='submit-btn'>
              <button
                className='button-2 w-button'
                disabled={!props?.currentAudioRecording?.size || props?.currentAudioRecording?.size === undefined}
                onClick={onSubmitRecording}
              >
                {!isSubmitting ? 'Submit' : <Loader showText={false} />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

const mapStateToProps = (state: any, ownProps: any) => ({
  ...ownProps,
  credentials: state.user.credentials,
  story: state.story.currentStory,
  errors: state.UI.errors,
  storyId: state.story.currentStory.id,
  lastStep: state.story.lastStep,
  lastStepEnum: state.story.lastStepEnum,
  storyLevel: state.story.storyLevel,
  currentAudioRecording: state.recorder.currentAudioRecording,
  user_auth_token: state.user.user_auth_token,
  skritter_token: state.user.skritter_token,
})

const mapActionsToProps = {
  submitRecording,
  setShowRecordingSuccess,
  setAudioRecording,
}
export default connect(mapStateToProps, mapActionsToProps)(StoryRecordingModal)
