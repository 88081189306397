import React from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'

interface IWordExamplesProps {
  openExampleModal?: boolean
  setOpenExampleModal?: any
  setWordDetailVisible?: any
}

const WordExamplesModal = React.forwardRef<HTMLInputElement, IWordExamplesProps>(
  ({ openExampleModal, setOpenExampleModal, setWordDetailVisible }: any, ref: any) => {
    const { wordDetails }: any = useSelector<any>(({ story }) => story)
    const findColorClass = (key) => {
      switch (key) {
        case 'new':
          return 'newword-highlight'

        case 'learning':
          return 'oldword-highlight'

        case 'growing':
          return 'growing_developing_mastering'

        case 'developing':
          return 'growing_developing_mastering'

        case 'mastered':
          return 'growing_developing_mastering'
        default:
          return ''
      }
    }

    const wordColorClass = findColorClass(wordDetails?.type)

    return (
      <div className='custom_modal'>
        <Modal
          show={openExampleModal}
          onHide={() => {
            setOpenExampleModal(false)
            setWordDetailVisible(true)
          }}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          className='common_modal_main_div'
        >
          <Modal.Body className='mobile_align_div'>
            <div className='modal_title_div'>
              <h4 className='modal_heading'>View sentence examples</h4>
              <div
                className='modal_close_icon_div'
                onClick={() => {
                  setOpenExampleModal(false)
                  setWordDetailVisible(true)
                }}
              >
                <img className='' alt={'icon'} src={'/images/close-icon-black.svg'} />
              </div>
            </div>

            <div ref={ref} className='sentence_list_main_div'>
              {wordDetails?.sentence?.map((sentenceInfo, i) => (
                <div key={i} className='sentence_box_div'>
                  <p className='sentence_english'>{sentenceInfo?.english}</p>
                  <div className='view_sentence_word_div'>
                    {sentenceInfo?.segmentation?.map((wordInfo) => (
                      <div>
                        {wordInfo?.pinyin ? (
                          <p className='sentence_pinyin'>{wordInfo?.pinyin}</p>
                        ) : (
                          <p className='sentence_pinyin' style={{ visibility: 'hidden' }}>
                            1
                          </p>
                        )}
                        <p
                          className={`sentence_sentence_text ${wordInfo?.word === wordDetails?.word && wordColorClass}`}
                        >
                          {wordInfo?.word}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  },
)

export default WordExamplesModal
