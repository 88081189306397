import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_IS_RESULT } from 'redux/types'
import AOS from 'aos'
import 'aos/dist/aos.css'

const HaveLearnedLanguage = ({ nextStep }) => {
  const dispatch = useDispatch()
  const { languageInfo, isLeadUser }: any = useSelector<any>(({ onboarding }) => onboarding)
  const handle = {
    navigate: (val: boolean, step: number) => {
      dispatch({ type: SET_IS_RESULT, payload: false })
      dispatch({ type: SET_IS_RESULT, payload: val })
      nextStep(step)
    },
  }

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <>
      <div className='w-container'>
        <div className='choose-lunguage-main-div'>
          <div className='common-title-sec'>
            <h2
              className='screen-sub-title'
              data-aos='fade-down'
              data-aos-duration='1000'
              // data-aos-easing='linear'
              data-aos-once='true'
              data-aos-delay='100'
            >
              Have you learned {languageInfo?.language || 'Chinese'} before?
            </h2>
            <p
              className='screen-para-text'
              data-aos='fade-down'
              data-aos-duration='1000'
              data-aos-once='true'
              data-aos-delay='100'
            >
              If you studied it before, but know less 100 words, choose "No".
            </p>
          </div>
          <div
            className='method-main-div'
            data-aos='fade-down'
            data-aos-duration='1000'
            // data-aos-easing='linear'
            data-aos-once='true'
            data-aos-delay='400'
          >
            <button
              onClick={() =>
                handle.navigate(
                  true,
                  isLeadUser ? (languageInfo?.preferences?.length ? 2 : 3) : languageInfo?.preferences?.length ? 5 : 6,
                )
              }
              className={'method-btn outline-btn-class box-shadow-cls'}
            >
              <label className='lung-text'>
                <svg width='38' height='32' viewBox='0 0 38 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M37.0315 0.624847C38.1793 1.57211 38.3282 3.2542 37.364 4.3819L14.564 31.0485C14.0587 31.6395 13.3176 31.9864 12.5321 31.9996C11.7465 32.0128 10.9938 31.6909 10.4682 31.1172L0.696795 20.4506C-0.30602 19.3559 -0.215693 17.6698 0.898546 16.6846C2.01278 15.6994 3.729 15.7881 4.73181 16.8828L12.4151 25.27L33.2073 0.951547C34.1715 -0.176149 35.8836 -0.322418 37.0315 0.624847Z'
                    fill='#57A773'
                  />
                </svg>
              </label>
              Yes
            </button>
            <button
              onClick={() => handle.navigate(false, isLeadUser ? 4 : 7)}
              className={'method-btn outline-btn-class box-shadow-cls wrong'}
            >
              <label className='lung-text'>
                <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M19.9803 16.001L31.1131 4.86816C31.6414 4.34073 31.9386 3.62501 31.9393 2.87846C31.94 2.1319 31.644 1.41566 31.1166 0.887301C30.5892 0.35894 29.8734 0.0617395 29.1269 0.0610802C28.3803 0.0604209 27.6641 0.356357 27.1357 0.883785L16.0029 12.0166L4.87011 0.883785C4.34175 0.355424 3.62514 0.0585938 2.87793 0.0585938C2.13071 0.0585938 1.4141 0.355424 0.885738 0.883785C0.357377 1.41215 0.0605469 2.12876 0.0605469 2.87597C0.0605469 3.62319 0.357377 4.3398 0.885738 4.86816L12.0185 16.001L0.885738 27.1338C0.357377 27.6621 0.0605469 28.3788 0.0605469 29.126C0.0605469 29.8732 0.357377 30.5898 0.885738 31.1182C1.4141 31.6465 2.13071 31.9433 2.87793 31.9433C3.62514 31.9433 4.34175 31.6465 4.87011 31.1182L16.0029 19.9853L27.1357 31.1182C27.6641 31.6465 28.3807 31.9433 29.1279 31.9433C29.8751 31.9433 30.5917 31.6465 31.1201 31.1182C31.6485 30.5898 31.9453 29.8732 31.9453 29.126C31.9453 28.3788 31.6485 27.6621 31.1201 27.1338L19.9803 16.001Z'
                    fill='#ED6A5E'
                  />
                </svg>
              </label>
              No
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default HaveLearnedLanguage
