export const {
  NODE_ENV,
  REACT_APP_MAAYOT_API_DEV,
  REACT_APP_MAAYOT_API_PROD,
  REACT_APP_MAAYOT_API_TEMP,
  REACT_APP_PUBLIC_POSTHOG_HOST,
  REACT_APP_PUBLIC_POSTHOG_KEY,
  REACT_APP_GTM_ID,
} = process.env

const MAAYOT_API = NODE_ENV === 'production' ? REACT_APP_MAAYOT_API_PROD : REACT_APP_MAAYOT_API_DEV

export const JSON_HEADER = {
  'Content-Type': 'application/json',
}
export default MAAYOT_API
