import React from 'react'

import { Word } from 'containers/story'

interface IQuizOptionsProps {
  quizDetail?: any
  showPinYin: any
  story: any
  activeIndex?: any
  handleClickWord: any
  iterator: any
}

const QuizOptions: React.FC<IQuizOptionsProps> = (props: any) => {
  const { quizDetail, showPinYin, story, activeIndex, handleClickWord, iterator } = props

  return (
    <>
      {quizDetail.opContect.map((wordObj, index) => {
        const isOldWord = story?.oldWords?.includes(wordObj.word)
        const isIncludePTag =
          wordObj.word.includes('<p>') || wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')
        const stripedWord = wordObj.word.replace(/<[^>]+>/g, '')
        return (
          <React.Fragment key={`${iterator}${index}`}>
            {isIncludePTag && index ? <br /> : ''}
            {stripedWord.length ? (
              <Word
                key={index}
                index={`${iterator}${index}`}
                isActive={`${iterator}${index}` === activeIndex}
                text={stripedWord}
                textObj={wordObj}
                isNewWord={stripedWord === story.newWord1 || stripedWord === story.newWord2}
                isOldWord={isOldWord}
                onClick={handleClickWord}
                pinYinText={showPinYin ? wordObj?.pinyin : null}
              />
            ) : (
              ''
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default React.memo(QuizOptions)
