import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import userReducer from './reducers/userReducer'
import uiReducer from './reducers/uiReducer'
import storyReducer from './reducers/storyReducer'
import quizReducer from './reducers/quizReducer'
import questionReducer from './reducers/questionReducer'
import streakReducer from './reducers/streakReducer'
import dashboardReducer from './reducers/dashboardReducer'
import recorderReducer from './reducers/recorderReducer'
import studentCorrectionsReducer from './reducers/studentCorrectionsReducer'
import pastStoriesReducer from './reducers/pastStoriesReducer'

import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import onBoardingReducer from './reducers/onBoardingReducer'

const persistConfig = {
  key: 'storymaayot',
  storage,
  whitelist: ['currentStory', 'lastStep', 'lastStepEnum', 'storyLevel', 'story_submitted'],
}

const quizPersistConfig = {
  key: 'quizmaayot',
  storage,
  whitelist: ['quizDetail', 'quizAnswer', 'isExistsAnswer'],
}

const questionPersistConfig = {
  key: 'questionmaayot',
  storage,
  whitelist: ['question', 'questionContent', 'curAnswer', 'answers', 'cursorRefId', 'cursorTs', 'count'],
}

const streakPersistConfig = {
  key: 'streakmaayot',
  storage,
  whitelist: ['current'],
}

const levelPersistConfig = {
  key: 'levelmaayot',
  storage,
  whitelist: ['level'],
}

const onboardingPersistConfig = {
  key: 'onboardingmaayot',
  storage,
  whitelist: [
    'languageInfo',
    'is_result',
    'stepper_step',
    'tempUser',
    'userType',
    // 'isFreeTrial',
    'isLeadUser',
    'learnAboutType',
    'learnAboutTitle',
    'learnAboutAnswer',
  ],
}

const initialState = {}
const middleware = [thunk]
const enhancers: any[] = []

const reducer = combineReducers({
  user: persistReducer(levelPersistConfig, userReducer),
  UI: uiReducer,
  story: persistReducer(persistConfig, storyReducer),
  pastStories: pastStoriesReducer,
  quiz: persistReducer(quizPersistConfig, quizReducer),
  question: persistReducer(questionPersistConfig, questionReducer),
  streak: persistReducer(streakPersistConfig, streakReducer),
  dashboard: dashboardReducer,
  onboarding: persistReducer(onboardingPersistConfig, onBoardingReducer),
  recorder: recorderReducer,
  corrections: studentCorrectionsReducer,
})

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension: any =
    ((window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()) || compose
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension)
  }
}

const store = createStore(reducer, initialState, compose(applyMiddleware(...middleware), ...enhancers))

export const persistor = persistStore(store)

export default store
