import React, { useEffect, useState } from 'react'
import { converterType, characterConst } from '../../shared/constant'
import { useSelector } from 'react-redux'
interface WordProps {
  index?: any
  text?: any
  onClick?: any
  isActive?: boolean
  isNewWord?: boolean
  isOldWord?: boolean
  isGrowingWord?: boolean
  isDevelopingWord?: boolean
  isMasteredWord?: boolean
  pinYinText?: any
  pinyinFontSize?: any
  chineseWordFontSize?: any
  textObj?: any
  sentance?: any
}

const Word: React.FC<WordProps> = (props: any) => {
  const {
    index,
    text,
    isNewWord,
    isOldWord,
    isGrowingWord,
    isDevelopingWord,
    isMasteredWord,
    isActive,
    onClick,
    pinYinText,
    pinyinFontSize,
    chineseWordFontSize,
    textObj,
    sentance,
  } = props
  const [showPinyin, setShowPinyin] = useState(false)
  const { pinyin_settings }: any = useSelector<any>(({ story }) => story)
  const onHover =
    (pinyin_settings?.new_word === 'on_hover' && isNewWord) ||
    (pinyin_settings?.recently_learned_word === 'on_hover' && isOldWord) ||
    (pinyin_settings?.known_word === 'on_hover' && (isGrowingWord || isDevelopingWord || isMasteredWord))
      ? true
      : false

  const always =
    (pinyin_settings?.new_word === 'always' && isNewWord) ||
    (pinyin_settings?.recently_learned_word === 'always' && isOldWord) ||
    (pinyin_settings?.known_word === 'always' && (isGrowingWord || isDevelopingWord || isMasteredWord))
      ? true
      : false

  const never =
    (pinyin_settings?.new_word === 'never' && isNewWord) ||
    (pinyin_settings?.recently_learned_word === 'never' && isOldWord) ||
    (pinyin_settings?.known_word === 'never' && (isGrowingWord || isDevelopingWord || isMasteredWord))
      ? true
      : false

  useEffect(() => {
    if (never) {
      setShowPinyin(false)
    }
    if (always) {
      setShowPinyin(true)
    }
  }, [pinyin_settings, onHover, always, never])

  const onWordClick = (e) => {
    onClick && onClick(e, textObj, sentance, index)
  }
  const className =
    `${onClick ? `word-translate` : ''} ` +
    `${isActive ? 'word-active' : ''} ` +
    `${isNewWord ? 'newword-highlight' : ''}` +
    `${isOldWord ? 'oldword-highlight' : ''}` +
    `${isGrowingWord ? 'growingword-highlight' : ''}` +
    `${isDevelopingWord ? 'developingword-highlight' : ''}` +
    `${isMasteredWord ? 'masterword-highlight' : ''}`

  return (
    <span className={`d-inline-grid ${pinYinText ? 'ml-2 mr-2' : ''}`} onClick={onWordClick}>
      <span className={'word-container'}>
        <span
          style={{ fontSize: chineseWordFontSize?.pinyinValue || '12px', lineHeight: '1.5', letterSpacing: '1.5px' }}
          className={`pinyin-word m-al-c main_title_small_text`}
        >
          {showPinyin && pinYinText?.toLowerCase()} &nbsp;
        </span>
        <span
          className={`${className} m-al-c main_title_big_text`}
          onMouseOver={() => (onHover ? setShowPinyin(true) : 'return false')}
          onMouseOut={() => (onHover ? setShowPinyin(false) : 'return false')}
        >
          {globalThis.characterPreference === characterConst.traditional
            ? converterType.converterTraditional(text)
            : converterType.converterSimplified(text)}
        </span>
      </span>
    </span>
  )
}
export default Word
