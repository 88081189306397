import React, { useState, useMemo } from 'react'
import Word from './Word'
interface ILeftStoryProps {
  story?: any
  handleClickWord?: any
  activeIndex?: any
  showPinYin?: boolean
  chineseWordFontSize?: any
}

const chevronUp = <i className='fa fa-chevron-up' />
const chevronDown = <i className='fa fa-chevron-down' />

const LeftStory: React.FC<ILeftStoryProps> = (props: any) => {
  const [showMessage, setShowMessage] = useState(-1)
  const [showChinese, setShowChinese] = useState(-1)
  const [showTranslation, setShowTranslation] = useState(false)

  const { handleClickWord, story, activeIndex, showPinYin, chineseWordFontSize } = props
  const data: any = [[]]
  story.storyContent.forEach((wordObj, index) => {
    const isIncludePTag =
      wordObj.word.includes('<p>') || wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')

    if (isIncludePTag && index) {
      data.push([])
    } else data[data.length - 1].push(wordObj)
  })

  const handleChangeTra = () => {
    setShowTranslation(!showTranslation)
  }
  const iconTra = useMemo(() => {
    return showTranslation ? chevronUp : chevronDown
  }, [showTranslation])

  return (
    <div className='w-col w-col-7 content-left'>
      <div
        style={{ fontSize: chineseWordFontSize?.storyContent, lineHeight: '1.5', letterSpacing: '1.5px' }}
        className='story-content story-event-select'
      >
        <>
          {data?.map((sentance, sIndex) => (
            <div
              key={sIndex}
              className={sIndex === showChinese ? 'sentance-hover p-1' : '  p-1'}
              onMouseEnter={() => {
                setShowMessage(sIndex)
              }}
              onMouseLeave={() => {
                setShowMessage(-1)
              }}
            >
              {sentance?.map((wordObj, index) => {
                const isOldWord = story?.oldWords?.includes(wordObj.word)
                const isNewWord =
                  (!story?.newWord1 ? false : story?.newWord1 === wordObj.word) ||
                  (!story?.newWord2 ? false : story?.newWord2 === wordObj.word)
                const isIncludePTag =
                  wordObj.word.includes('<p>') || wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')
                const stripedWord = wordObj.word.replace(/<[^>]+>/g, '')
                return (
                  <React.Fragment key={index}>
                    {isIncludePTag && index ? <br /> : ''}
                    {stripedWord.length ? (
                      <Word
                        key={index}
                        index={`L${sIndex + '-' + index}`}
                        isActive={`L${sIndex + '-' + index}` === activeIndex}
                        text={stripedWord}
                        isNewWord={isNewWord}
                        textObj={wordObj}
                        isOldWord={!isNewWord && isOldWord}
                        onClick={handleClickWord}
                        pinYinText={showPinYin ? wordObj?.pinyin : null}
                        chineseWordFontSize={chineseWordFontSize}
                      />
                    ) : (
                      ''
                    )}
                  </React.Fragment>
                )
              })}
            </div>
          ))}
        </>
      </div>
      <div className='bg-light-gray translation-box'>
        <div
          className='translation-button'
          onClick={() => {
            handleChangeTra()
          }}
        >
          {showTranslation ? `  Hide ` : `  Show `} English Translation
          {iconTra}
        </div>

        {showTranslation && (
          <>
            {/* <div className='pt-10 border-bottom'></div> */}
            <div
              style={{ fontSize: chineseWordFontSize?.storyContent, lineHeight: '1.5', letterSpacing: '1.5px' }}
              className='story-content story-event-select m-b-10'
            >
              {story?.englishContent?.map((sentance, index) => (
                <div
                  className={index === showMessage ? 'sentance-hover translation-text p-1' : 'translation-text p-1'}
                  onMouseEnter={() => {
                    setShowChinese(index)
                  }}
                  onMouseLeave={() => {
                    setShowChinese(-1)
                  }}
                >
                  {sentance}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
export { Word }
export default LeftStory
