import React from 'react'
import './skeleton-loader.css'

const SkeletonLoader = () => {
  return (
    <div className='skeleton_loader_main_div'>
      <div className='skeleton_loader_card_div'>
        <div className='left_sec_box skeleton_loader_cls'></div>
        <div className='right_sec_box'>
          <p className='card_tags light_orange'>Recommended</p>
          <div className='card_name_box1 skeleton_loader_cls'></div>
          <div className='card_name_box2 skeleton_loader_cls'></div>
          <div className='card_name_box3 skeleton_loader_cls'></div>
        </div>
      </div>
      <div className='skeleton_loader_card_div'>
        <div className='left_sec_box skeleton_loader_cls'></div>
        <div className='right_sec_box'>
          <p className='card_tags light_green'>Latest</p>
          <div className='card_name_box1 skeleton_loader_cls'></div>
          <div className='card_name_box2 skeleton_loader_cls'></div>
          <div className='card_name_box3 skeleton_loader_cls'></div>
        </div>
      </div>
    </div>
  )
}

export default SkeletonLoader
