export const FreeTitle = [
  'Why only learn on Sundays?',
  "You're a free user",
  'Why only learn on Sundays?',
  'Hey there',
  'Upgrade now',
]
export const StandardTitle = [
  'Hey there!',
  'Howdy!',
  "Let's get started!",
  '你好!',
  'Time for some learning!',
  "Let's get some reading done!",
  'Ready to learn some more?',
]
export const PremiumTitle = [
  'Hey there!',
  'Howdy!',
  "Let's get started!",
  '你好!',
  'Time for some learning!',
  "Let's get some reading done!",
  'Ready to learn some more?',
]

export const ProTitle = [
  'Hey there!',
  'Howdy!',
  "Let's get started!",
  '你好!',
  'Time for some learning!',
  "Let's get some reading done!",
  'Ready to learn some more?',
]

export const landingPageTitle = [
  'practice makes perfect, start reading.',
  "let's get some learning done.",
  'ready to learn something new today?',
  'let’s expand your vocabulary!',
  'today is a great day for learning!',
  'practice makes perfect, start reading!',
  'ready to master new phrases?',
  'today is perfect for learning.',
  'let’s dive into today’s lesson.',
  'embrace new words, they’re your friends.',
  'ready to enhance your vocabulary?',
  'every new word is a step closer to fluency.',
  'small steps every day lead to big achievements.',
  'let’s take your language skills to the next level.',
  'focus on progress, not perfection.',
  'let’s make every word count.',
]

export const FreePlanFeatures = [
  {
    label: 'Sunday Weekly Story',
    checked: true,
  },
  { label: 'Daily Engaging Story' },
  { label: 'Daily Quiz' },
  { label: 'Daily Recording' },
  { label: 'Daily Writing Prompt' },
  { label: 'Daily Writing Corrections' },
  { label: 'Daily Speaking Corrections' },
  { label: 'Access Past Stories' },
]

export const StandardFeatures = [
  'Daily story in Mandarin',
  'Daily native recording',
  'Daily story quiz',
  'Daily new characters',
  'Daily writing prompt',
  'One click definitions',
  '5 Days Past Stories',
]

export const StandardPlanFeatures = [
  {
    label: 'Daily story in Mandarin',
    checked: true,
  },
  {
    label: 'Daily native recording',
    checked: true,
  },
  {
    label: 'Daily story quiz',
    checked: true,
  },
  {
    label: 'Daily new characters',
    checked: true,
  },
  {
    label: 'Daily writing prompt',
    checked: true,
  },
  {
    label: 'One-click definitions',
    checked: true,
  },
  {
    label: '5 Days Past Stories',
    checked: true,
  },
  {
    label: 'Daily writing correction',
    checked: false,
  },
  {
    label: 'Daily speaking correction',
    checked: false,
  },
  {
    label: '30 Days Past Stories',
    checked: false,
  },
]

export const PremiumFeatures = ['All standard features', 'Weekly 1-on-1 tutoring', 'Daily corrections']

export const PremiumPlanFeatures = [
  {
    label: 'All Standard Plan Features',
    checked: true,
  },
  {
    label: 'Weekly 1-on-1 tutoring',
    checked: true,
  },
  {
    label: 'Daily corrections',
    checked: true,
  },
]

export const ProPlanFeatures = [
  {
    label: 'Daily story in Mandarin',
    checked: true,
  },
  {
    label: 'Daily native recording',
    checked: true,
  },
  {
    label: 'Daily story quiz',
    checked: true,
  },
  {
    label: 'Daily new characters',
    checked: true,
  },
  {
    label: 'Daily writing prompt',
    checked: true,
  },
  {
    label: 'One-click definitions',
    checked: true,
  },
  {
    label: 'Daily writing correction & feedback',
    checked: true,
  },
  {
    label: 'Daily speaking correction & feedback',
    checked: true,
  },
  {
    label: '30 Days of Past Stories',
    checked: true,
  },
]

export const FONT_SIZE_LIST = [
  {
    label: '18px',
    sessionTitle: '24px',
    storyTitle: '20px',
    storyContent: '18px',
    pinyinValue: '12px',
    newWordsTitle: '36px',
    newWordsExample: '14px',
  },
  {
    label: '22px',
    sessionTitle: '28px',
    storyTitle: '24px',
    storyContent: '22px',
    pinyinValue: '16px',
    newWordsTitle: '40px',
    newWordsExample: '18px',
  },
  {
    label: '26px',
    sessionTitle: '32px',
    storyTitle: '28px',
    storyContent: '26px',
    pinyinValue: '18px',
    newWordsTitle: '44px',
    newWordsExample: '22px',
  },
  {
    label: '30px',
    sessionTitle: '36px',
    storyTitle: '32px',
    storyContent: '30px',
    pinyinValue: '22px',
    newWordsTitle: '48px',
    newWordsExample: '26px',
  },
]
