import React, { useEffect } from 'react'
import history from '../browserHistory'
import { STORIES } from '../paths'
import { connect } from 'react-redux'
import { membershipNameType } from 'shared/constant'

const Home: React.FC = (props: any) => {
  useEffect(() => {
    const isTeacher = props.membership?.name === membershipNameType.Teacher
    if (props.authenticated) {
      // if (isTeacher) {
      //   // history.push(DASHBOARD)
      //   history.push(INTRODUCTION)
      // } else if (idValidAccount(props.membership?.name, props.membership?.status) || isSunday()) {
      //   history.push(INTRODUCTION)
      // } else {
      //   history.push(STORY_FINISHED)
      // }
      history.push(STORIES)
    }
  }, [])
  return <h1>Home Login Success</h1>
}
const mapStateToProps = (state: any) => ({
  authenticated: state.user.authenticated,
  membership: state.user.membership,
})
export default connect(mapStateToProps)(Home)
