import React, { useEffect } from 'react'
import Header from '../../containers/header-old/HeaderOld'
import HeaderOld from '../../containers/header-old/HeaderOld'
declare let $: any
export const NotFound: React.FC = () => {
  useEffect(() => {
    $('.nav-menu').hide()
    const footer = document.getElementById('maayot-footer')
    if (footer) {
      footer.style.display = 'none'
    }
    return () => {
      if (footer) {
        footer.style.display = 'block'
      }
    }
  }, [])
  return (
    <>
      <HeaderOld />
      <div className='utility-page-wrap w-row'>
        <div className='utility-page-wrap-left'>
          <img src='images/Designs.png' alt='' className='image-42' />
        </div>
        <div className='utility-page-content utility-page-wrap-right'>
          <h2 className='heading-8'>Page Not Found</h2>
          <div className='div-block-6'>The page you are looking for doesn't exist or has been moved.</div>
        </div>
      </div>
    </>
  )
}
