import Diff from 'containers/Diff'
import PLayer from 'containers/player/Player'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { setAudioRecording } from 'redux/actions/recorderAction'
import { getSignedUrl } from 'utils/S3Upload'
import Translate from '../../containers/popup/Translate'

// import { GetStudentCorrection } from '../../redux/actions/studentCorrectionsAction'
import {
  GetStudentCorrection,
  getStory,
  getQuestion,
  setCorrections,
} from '../../redux/actions/studentCorrectionsAction'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Story from './Story'
import Loader from 'containers/loader/loader'
import { characterConst, converterType, membershipNameType, wordType } from 'shared/constant'
import CorrectionAnswer from './CorrectionAnswer'
import CorrectionQuestion from './CorrectionQuestion'
import { translate } from 'redux/actions/storyAction'
interface ICorrectionProps {
  credentials?: any
}
const Corrections: React.FC<ICorrectionProps> = (props: any) => {
  const { credentials, correctionsMaxCount, membership } = props
  const { memberId } = credentials

  const [audioRecordingUrl, setAudioRecordingUrl] = useState('')
  const [correctionRecordingUrl, setCorrectionRecordingUrl] = useState('')
  const [firstLineStory, setFirstLineStory] = useState<any>({})
  const [isStoryAvailable, setIsSisStoryAvailable] = useState(false)
  const [loadingMoreStatus, setLoadingMoreStatus] = useState(false)
  const [loadingContentStatus, setLoadingContentStatus] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(5)

  const contentStoryRef = useRef(document.createElement('div'))
  const [transVisible, setTransVisible] = useState(false)
  const [transData, setTransData] = useState({})
  const [activeIndex, setActiveIndex] = useState('-1')
  const popupRef = useRef<HTMLInputElement>(null)
  // const [recordingError, setRecordingError] = useState('')
  // const [totalCorrection, setTotalCorrection] = useState(0)
  const [correctionsDetails, setCorrectionsDetails] = useState<any>({
    show: false,
    id: '',
  })
  const answersData: any = useSelector<any>(({ corrections }) => corrections.Data)
  const question = props.question
  const questionContent = props.questionContent
  const chineseWordFontSize = useSelector((state) => state?.['dashboard']?.chineseWordFontSize)

  const fetchData = async () => {
    // const currentCorrections = answersData ? [...answersData] : []
    const currentCorrections = !answersData ? [...answersData] : []
    const currentPage = page ? page : 1
    const currentPer_Page = perPage + 5
    setPerPage(currentPer_Page)
    const newCorrections = await props.GetStudentCorrection(currentPer_Page, currentPage, memberId) //.then((res) => {
    // setTotalCorrection(newCorrections.count)
    const allCorrections = currentCorrections.concat([...newCorrections.Data])
    props.setCorrections(allCorrections)

    if (allCorrections.length < newCorrections.count) setPage(page + 1)
  }

  useEffect(() => {
    if (memberId) {
      // props.GetStudentCorrection(memberId)
      Promise.resolve(props.GetStudentCorrection(perPage.toString(), page.toString(), memberId)).then(() =>
        setIsSisStoryAvailable(true),
      )
    }
  }, [memberId])

  useEffect(() => {
    setFirstLineStory({})
    const firstLineWords: string[] = []
    let firstLineBreak = false

    props?.currentStory?.storyContent?.forEach((wordObj) => {
      firstLineBreak =
        !firstLineBreak && (wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')) ? true : firstLineBreak

      if (!firstLineBreak) firstLineWords.push({ ...wordObj })

      return wordObj
    })
    setFirstLineStory({
      arContent: firstLineWords,
      storyContent: firstLineWords,
      newWord1: props?.currentStory.newWord1,
      newWord2: props?.currentStory.newWord2,
      oldWords: props?.currentStory.oldWords,
      showPinYin: false,
    })
  }, [props.currentStory])

  useEffect(() => {
    setAudioRecording({})
    setAudioRecordingUrl('')
    setCorrectionRecordingUrl('')
    // setRecordingError('')
  }, [answersData])

  useEffect(() => {
    correctionsDetails?.id !== '' &&
      answersData.forEach((element) => {
        if (element?.storyId === correctionsDetails?.id) {
          // Change isOpen status
          element.isOpen = correctionsDetails?.show

          // Get recordingUrl
          if (element?.recordingUrl && element?.recordingUrl !== '') {
            // setAudioRecordingUrl(`${MAAYOT_API}getAudio/${encodeURIComponent(element?.recordingUrl)}`)
            // https://dev.maayot.com/api/v1/getAudio/658aa5909ce036000879d070%2FZe5i8xIAACUAu35R-3fec084-f133-8ee0-7471-7228e34c65b.mp3
            getSignedUrl({ key: element?.recordingUrl })
              .then((res) => {
                setAudioRecordingUrl(res)
              })
              .catch(() => setAudioRecordingUrl(''))
          }

          // Get correctedRecordingUrl
          if (element?.correctedRecordingUrl && element?.correctedRecordingUrl !== '') {
            // setCorrectionRecordingUrl(`${MAAYOT_API}getAudio/${encodeURIComponent(element?.correctedRecordingUrl)}`)
            getSignedUrl({ key: element?.correctedRecordingUrl })
              .then((res) => {
                setCorrectionRecordingUrl(res)
              })
              .catch(() => setCorrectionRecordingUrl(''))
          }

          // call Story and question api below
          Promise.all([
            props.getQuestion(element?.storyId, element?.level, memberId),
            props.getStory(element?.storyId, element?.level, memberId),
          ]).then(() => setLoadingContentStatus(false))
        }
      })
  }, [correctionsDetails?.id])

  useEffect(() => {
    document.addEventListener('scroll', onTranslateClose)
    document.addEventListener('click', onDocumentClick)
    return () => {
      document.removeEventListener('scroll', onTranslateClose)
      document.removeEventListener('click', onDocumentClick)
    }
  }, [contentStoryRef, props.questionDetail])

  const setTranslate = (word: string, posTop: number, posLeft: number, data: any) => {
    setTransVisible(true)
    const newWord1 =
      globalThis.characterPreference === characterConst.traditional
        ? converterType.converterTraditional(props.currentStory?.newWord1)
        : converterType.converterSimplified(props.currentStory?.newWord1)
    const newWord2 =
      globalThis.characterPreference === characterConst.traditional
        ? converterType.converterTraditional(props.currentStory?.newWord2)
        : converterType.converterSimplified(props.currentStory?.newWord2)
    const isOldWord = props?.story?.oldWords?.includes(word)

    const type =
      newWord1 === word || newWord2 === word ? wordType.NewWord : isOldWord ? wordType.OldWord : wordType.Word
    setTransData({
      type,
      top: posTop,
      left: posLeft,
      word,
      definition: data.pinyin,
      example1: data.english,
      example2: '',
      level: 'HSK 5',
    })
  }

  const handleTranslate = (event, iterator) => {
    if (
      (event.type === 'mouseover' || event.type === 'click') &&
      event.target.innerText &&
      event.target.innerText.length > 0
    ) {
      trans(event.target.innerText, event.clientY, event.clientX, iterator)
    }
  }

  const trans = (word: string, posTop: number, posLeft: number, iterator: number) => {
    const allData = [
      ...props?.currentStory?.arContent,
      ...questionContent,
      ...answersData[iterator].answerContect,
      ...answersData[iterator].correctedAnswerContect,
    ]

    if (/^[&.-”“，、；？@!#$%^&*()]+$/.test(word) || word.trim() === '。') {
      setTransVisible(false)
      return
    }

    const wordTranslate = allData.filter(
      (iword) =>
        (globalThis.characterPreference === characterConst.traditional
          ? converterType.converterTraditional(iword.word)
          : converterType.converterSimplified(iword.word)) === word,
    )
    if (wordTranslate && wordTranslate.length > 0) {
      setTranslate(word, posTop, posLeft, wordTranslate[0])
    } else {
      translate(word)
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          if (data && data.length > 0) {
            setTranslate(word, posTop, posLeft, data[0])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const onTranslateClose = () => {
    setTransVisible(false)
  }
  const onDocumentClick = (e) => {
    if (!popupRef?.current?.contains(e.target)) {
      setTransVisible(false)
    }
  }

  const handleClickWord = (event, key) => {
    handleTranslate(event, key)
    setActiveIndex(activeIndex === key ? `-1` : key)
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <div className='correction-div-main'>
      <div className='section-correction-body  w-container'>
        <div className='section-correction-header'>
          <div className='w-row'>
            <div className='w-col w-col-12 mb-20'>
              <span className='correction-title'> Corrections </span>
            </div>
          </div>
        </div>

        {isStoryAvailable ? (
          <div className='correction-items'>
            {answersData?.map((item, iterator) => {
              return (
                <React.Fragment key={item.storyId}>
                  <div className='correction-item'>
                    <div className='w-row  w-d-flex section-story-content mb-20'>
                      <div className='w-col-12 w-col-medium-12 w-col-small-12 w-col-tiny-12 content-left box-padding border-full border-radius-10'>
                        <div className='answer-box box-border'>
                          <div
                            className='correction-item-show'
                            onClick={() => {
                              setLoadingContentStatus(true)
                              setCorrectionsDetails({
                                show: correctionsDetails.id === item.storyId ? !correctionsDetails.show : true,
                                id: item.storyId,
                              })
                            }}
                          >
                            {/* <div className='correction-date'> */}
                            <span className='box-title '>
                              {/* parse integer below */}
                              <span>{moment(item.createdAt).format('DD MMM YYYY')}</span>
                              {/* parse string below */}
                              {/* <span>{moment('1454521239279', 'x').format('DD MMM YYYY hh:mm a')}</span> */}
                            </span>
                            {/* </div> */}
                            <span className='correction-date-downarrow'>
                              <i
                                className={
                                  correctionsDetails.id === item.storyId && correctionsDetails.show
                                    ? 'fa fa-chevron-up'
                                    : 'fa fa-chevron-down'
                                }
                              ></i>
                            </span>
                          </div>
                          {correctionsDetails.id === item.storyId &&
                            correctionsDetails.show &&
                            firstLineStory &&
                            // isStoryAvailable ? (
                            (loadingContentStatus === false ? (
                              <>
                                <div className='story-content correction-common-padding border-top'>
                                  {firstLineStory?.arContent && firstLineStory?.arContent.length ? (
                                    <Story
                                      user_auth_token={props?.user_auth_token}
                                      story={firstLineStory}
                                      handleClickWord={handleClickWord}
                                      activeIndex={activeIndex}
                                      chineseWordFontSize={chineseWordFontSize}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </>
                            ) : (
                              <Loader />
                            ))}
                        </div>

                        <div className='opencorrection-s1'>
                          {correctionsDetails.id === item.storyId &&
                            correctionsDetails.show &&
                            loadingContentStatus === false && (
                              <>
                                <div className='w-row  w-d-j-s-flex section-story-content correction-withoutTop-padding'>
                                  <div className='w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-12 content-left box-padding'>
                                    <div className='correction-answer-box'>
                                      {item?.recordingUrl ? (
                                        <>
                                          <span className='box-title mb-10'> Your Recording</span>
                                          {!answersData?.isOpen && audioRecordingUrl !== '' && item?.recordingUrl ? (
                                            <>
                                              <div className='w-row w-col-11 w-col-medium-11 w-col-small-12 w-col-tiny-12 box-audio'>
                                                <PLayer audio={audioRecordingUrl}></PLayer>
                                              </div>
                                            </>
                                          ) : (
                                            'Audio Not Found'
                                          )}
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                  <div className='w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-12 content-left'>
                                    <div className='correction-box'>
                                      <div className='w-row w-col-12 w-col-medium-11 w-col-small-12 w-col-tiny-12 box-audio'>
                                        {item?.correctedRecordingUrl ? (
                                          <>
                                            <span className='box-title'> Teacher Correction </span>
                                            {!answersData?.isOpen &&
                                            correctionRecordingUrl !== '' &&
                                            item?.correctedRecordingUrl ? (
                                              <>
                                                <PLayer audio={correctionRecordingUrl}></PLayer>
                                              </>
                                            ) : (
                                              'Audio Not Found'
                                            )}
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr color='#f4f4f4' />
                              </>
                            )}
                        </div>

                        <div className='opencorrection-s2'>
                          {correctionsDetails.id === item.storyId &&
                            correctionsDetails.show &&
                            loadingContentStatus === false && (
                              <>
                                <div className='w-row  w-d-flex section-story-content correction-common-padding'>
                                  <div className='w-col-12 w-col-medium-12 w-col-small-12 w-col-tiny-12 content-left box-padding'>
                                    <div className='correction-answer-box'>
                                      <div className='copy-title-div mt-10'>
                                        {question ? (
                                          <h4>
                                            <CorrectionQuestion
                                              questionDetail={questionContent}
                                              showPinYin={false}
                                              handleClickWord={handleClickWord}
                                              activeIndex={activeIndex}
                                              story={props?.currentStory}
                                              chineseWordFontSize={chineseWordFontSize}
                                            />
                                          </h4>
                                        ) : (
                                          'Loading...'
                                        )}
                                        {answersData?.answer ? (
                                          <CopyToClipboard text={answersData?.answer}>
                                            <button className='copy-icon'>
                                              <i className='fa fa-clone'></i>
                                            </button>
                                          </CopyToClipboard>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                      <div className='box-title mb-10'> Your Writing </div>

                                      <div className='story-content story-words'>
                                        {item?.answer ? (
                                          <CorrectionAnswer
                                            answerDetail={item?.answerContect}
                                            showPinYin={false}
                                            handleClickWord={handleClickWord}
                                            activeIndex={activeIndex}
                                            iterator={iterator}
                                            story={props?.currentStory}
                                            chineseWordFontSize={chineseWordFontSize}
                                          />
                                        ) : (
                                          'No answer submitted'
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className='w-col-12 w-col-medium-12 w-col-small-12 w-col-tiny-12 content-left'>
                                    <div className='correction-box'>
                                      <div className='correction-box-content box-padding'>
                                        <div className='box-title mb-10'> Teacher Corrections </div>
                                        <div className='correction-words'>
                                          {/* Add text area*/}
                                          <div className='story-content story-words'>
                                            {item?.correctedAnswer ? (
                                              <CorrectionAnswer
                                                answerDetail={item?.correctedAnswerContect}
                                                showPinYin={false}
                                                handleClickWord={handleClickWord}
                                                activeIndex={activeIndex}
                                                iterator={iterator}
                                                story={props?.currentStory}
                                                chineseWordFontSize={chineseWordFontSize}
                                              />
                                            ) : (
                                              'No answer correction submitted'
                                            )}

                                            {/* <strong> Preview Correction </strong>
                                            {item?.answer?.length ? (
                                              <Diff
                                                string1={item?.answer}
                                                string2={
                                                  item?.correctedAnswer
                                                    ? item?.correctedAnswer
                                                    : 'No answer correction submitted'
                                                }
                                                mode='characters'
                                              />
                                            ) : (
                                              ''
                                            )} */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {item?.answer?.length !== 0 && item?.correctedAnswer?.length !== 0 && (
                                    <div className='w-col-12 w-col-medium-12 w-col-small-12 w-col-tiny-12 content-left'>
                                      <div className='correction-box'>
                                        <div className='correction-box-content box-padding'>
                                          <div className='box-title mb-10'> Preview Corrections </div>
                                          <div className='correction-words'>
                                            {/* Add text area*/}
                                            <div className='story-content story-words'>
                                              {item?.answer?.length ? (
                                                <Diff
                                                  string1={item?.answer}
                                                  string2={
                                                    item?.correctedAnswer
                                                      ? item?.correctedAnswer
                                                      : 'No answer correction submitted'
                                                  }
                                                  mode='characters'
                                                  chineseWordFontSize={chineseWordFontSize}
                                                />
                                              ) : (
                                                'No Preview correction Found'
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            })}
            {loadingMoreStatus === true && (
              <div className={`m-center m-loading`}>
                <div className='m-loading-ellipsis'>
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                <span className={'m-loading-text'}>Loading</span>
              </div>
            )}
            {/**
             * hide Show more button
             * if available data count is reached
             */}
            {correctionsMaxCount >= perPage && (
              <div
                className='see-more-btn text-center'
                onClick={() => {
                  setLoadingMoreStatus(true)
                  Promise.resolve(fetchData()).then(() => setLoadingMoreStatus(false))
                }}
              >
                <button>
                  Show more{' '}
                  <span>
                    <i className='fa fa-chevron-down'></i>
                  </span>
                </button>
              </div>
            )}
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <Translate ref={popupRef} visible={transVisible} data={transData} onClose={onTranslateClose} />
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  user_auth_token: state.user.user_auth_token,
  credentials: state.user.credentials,
  membership: state.user.membership,
  currentStory: state.corrections.currentStory,
  question: state.corrections.question,
  questionContent: state.corrections.questionContent,
  correctionsMaxCount: state.corrections.correctionsMaxCount,
})
const mapActionsToProps = {
  GetStudentCorrection,
  getStory,
  getQuestion,
  setCorrections,
}
export default connect(mapStateToProps, mapActionsToProps)(Corrections)
