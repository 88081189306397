import React from 'react'
import './App.css'
import { Switch, Route, Router, Redirect } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import User from './components/User'
import history from './browserHistory'
import PrivateRoute from './utils/PrivateRoute'
import Home from './components/Home'
import MembersAccount from './components/account'
import Login from './components/login/Login'
import Introduction from './components/introduction/Introduction'
import Story from './components/story/Story'
import Quiz from './components/quiz/Quiz'
import Question from './components/question'
import SucessPaid from './components/success-paid'
import { NotFound } from './components/404/404'
import Footer from './containers/footer/Footer'
import StoryFinished from './components/story-finished'
import Success from './components/success'
import Dashboard from 'components/dashboard/Dashboard'
import CreateMeet from 'components/meet/CreateMeet'
import Meet from 'components/meet/Meet'

import {
  ACCOUNT,
  NOT_FOUND,
  STORY_FINISHED,
  MEMBERS_ACCOUNT,
  INTRODUCTION,
  LOGIN,
  STORY,
  QUIZ,
  QUESTION,
  SUCCESS_PAID,
  SUCCESS,
  DASHBOARD,
  CREATE_MEET,
  LIVE_MEET,
  PRO_MEET,
  CORRECTIONS,
  PASTSTORIES,
  STORY_STREAKS,
  CHOOSE_LANGUAGE,
  NEW_USER_PATH,
  OLD_USER_PATH,
  LEAD_USER_PATH,
  STORIES,
  LEAD_USER2_PATH,
  NEW_USER2_PATH,
} from 'paths'
import Corrections from 'components/Corrections/Corrections'

import SkritterCallback from 'components/skritter/CallbackComponent'
import StoryCompletedStreak from 'components/StoryStreak'
import ChooseLanguage from 'components/choose-language'
import OnBoarding from 'components/onboarding'
import PastStories from 'components/stories/PastStories'
import Stories from 'components/landing-page'
import { REACT_APP_GTM_ID } from 'redux/actions/config'

globalThis.characterPreference = ''

const tagManagerArgs = {
  gtmId: REACT_APP_GTM_ID || '',
}

TagManager.initialize(tagManagerArgs)

function App() {
  // check height element

  window.dataLayer.push({
    event: 'pageview',
    page: {
      url: location,
      title: 'maayot • Learn Chinese as You Read It',
    },
  })

  // const footerRender =
  //   history?.location?.pathname.includes('integrations/beeminder') ||
  //   history?.location?.pathname.includes(CHOOSE_LANGUAGE) ||
  //   history?.location?.pathname.startsWith('/learn')
  //     ? false
  //     : true

  return (
    <div className='App'>
      <Router history={history}>
        <Switch>
          <PrivateRoute exact path='/' component={Home}></PrivateRoute>
          <PrivateRoute exact path='/user' component={User}></PrivateRoute>
          <PrivateRoute exact path={STORIES} component={Stories}></PrivateRoute>
          <PrivateRoute exact path={INTRODUCTION} component={Introduction} showNav={true}></PrivateRoute>
          <PrivateRoute exact path={PASTSTORIES} component={PastStories} showCorrections={true}></PrivateRoute>
          <PrivateRoute exact path={`${STORY}/:id`} component={Story} showNav={true} />
          <PrivateRoute exact path={`${QUIZ}/:id`} component={Quiz} showNav={true} />
          <PrivateRoute exact path={`${QUESTION}/:id`} component={Question} showNav={true}></PrivateRoute>
          <PrivateRoute exact path={STORY_FINISHED} component={StoryFinished} showNav={true}></PrivateRoute>
          <PrivateRoute exact path={CORRECTIONS} component={Corrections} showCorrections={true}></PrivateRoute>
          <PrivateRoute exact path={ACCOUNT} component={MembersAccount}></PrivateRoute>
          <PrivateRoute exact path={SUCCESS} component={Success}></PrivateRoute>
          <PrivateRoute exact path={DASHBOARD} component={Dashboard} showDashboard={true}></PrivateRoute>
          <PrivateRoute exact path={CREATE_MEET} component={CreateMeet} showDashboard={true}></PrivateRoute>
          <PrivateRoute exact path={`${LIVE_MEET}/:id`} component={Meet} showDashboard={true}></PrivateRoute>
          <PrivateRoute exact path={`${PRO_MEET}/:id`} component={Meet} showDashboard={true}></PrivateRoute>
          <Route path={'/callback'} component={SkritterCallback}></Route>
          <Route path={MEMBERS_ACCOUNT}>
            <Redirect to={INTRODUCTION} />
          </Route>
          <Route path={CHOOSE_LANGUAGE} exact component={ChooseLanguage} />
          <Route path={NEW_USER_PATH} component={OnBoarding} />
          <Route path={OLD_USER_PATH} component={OnBoarding} />
          <Route path={LEAD_USER_PATH} component={OnBoarding} />
          // temp route
          <Route path={NEW_USER2_PATH} component={OnBoarding} />
          <Route path={LEAD_USER2_PATH} component={OnBoarding} />
          <Route exact path={`${STORY_STREAKS}/:memberId/storiesread`} component={StoryCompletedStreak}></Route>
          <Route path={LOGIN} component={Login}></Route>
          <Route path={SUCCESS_PAID} component={SucessPaid}></Route>
          <Route path={NOT_FOUND} component={NotFound}></Route>
          <Route path='**'>
            <Redirect to={NOT_FOUND} />
          </Route>
        </Switch>
        {/* {footerRender && <Footer />} */}
      </Router>
    </div>
  )
}

export default App
