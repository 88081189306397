import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import store, { persistor } from './redux/stores'
import { checkAuthen } from './redux/actions/userActions'
import { checkStoryExpiration } from 'hooks/useCheckStory'
import { resetStory } from 'redux/actions/storyAction'
import { CHOOSE_LANGUAGE, STORIES } from 'paths'
import history from 'browserHistory'
import { SET_STEPPER_STEP } from 'redux/types'
import { PostHogProvider } from 'posthog-js/react'
import { REACT_APP_PUBLIC_POSTHOG_HOST, REACT_APP_PUBLIC_POSTHOG_KEY } from 'redux/actions/config'

const options = {
  api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
}

const check = async (currentStory, level, memberId) => {
  const isUpdated = checkStoryExpiration(currentStory)
  if (isUpdated) {
    await resetStory(level, memberId, currentStory.id)
    return isUpdated
  }
  return false
}
// call check authentication
const CheckAuthApp = () => {
  const dispatch = useDispatch()
  const { languageInfo, userType, isLeadUser }: any = useSelector<any>(({ onboarding }) => onboarding)

  // temporarily added logic

  if (window.location.pathname.includes('/success-paid')) {
    dispatch({ type: SET_STEPPER_STEP, payload: isLeadUser ? 5 : 3 })
    history.replace(`/learn-${languageInfo?.language?.toLowerCase()}/${userType}2`)
    checkAuthen()
  }

  // this logic will be set after a/b testing
  // window.location.pathname.includes('/onboarding') || window.location.pathname.includes('/success-paid'))

  if (window.location.pathname.includes('/onboarding')) {
    dispatch({ type: SET_STEPPER_STEP, payload: isLeadUser ? 5 : 3 })
    history.replace(`/learn-${languageInfo?.language?.toLowerCase()}/${userType}`)
    window.dataLayer.push({
      event: 'onboarding',
      page: {
        url: location,
        title: 'maayot • Learn Chinese as You Read It',
      },
    })
    checkAuthen()
  }
  useEffect(() => {
    if (
      history?.location?.pathname.includes('integrations/beeminder') ||
      history?.location?.pathname.includes(CHOOSE_LANGUAGE) ||
      history?.location?.pathname.startsWith('/learn')
    ) {
      console.log('return from check')
      return
    }
    checkAuthen().then(async () => {
      console.log('checkAuthen called')
      const currentStory = store?.getState()?.story?.currentStory
      const credentials = store?.getState()?.user?.credentials
      const { memberId } = credentials
      const level = store?.getState()?.story?.storyLevel
      let checkRes = false
      if (currentStory?.id) {
        checkRes = await check(currentStory, level, memberId)
      }
      if (checkRes) {
        history.push(STORIES)
      }
    })
  }, [])
  return <App />
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <PostHogProvider apiKey={REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
        <CheckAuthApp />
      </PostHogProvider>
      {/* <App /> */}
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
