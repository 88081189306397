import React, { useEffect, useMemo, useRef, useState } from 'react'
import { convertAudioTime, getBlobDuration } from '../../utils/utils'
import StoryRecordingModal from '../../components/story/StoryRecordingModal'
import { accountTab, membershipNameType, PlanChangeLink } from 'shared/constant'
import { useSelector } from 'react-redux'
import history from '../../browserHistory'
import { ACCOUNT } from 'paths'
const speedList = [
  { label: '0.6x', value: '0.6' },
  { label: '0.8x', value: '0.8' },
  { label: '1.0x (Normal)', value: '1.0' },
  { label: '1.2x', value: '1.2' },
  { label: '1.4x', value: '1.4' },
]

interface IPlayerProps {
  audio: string
  allowRecording?: boolean
}
const PLayer: React.FC<IPlayerProps> = ({ audio, allowRecording = false }: any) => {
  const audioRef = useMemo(() => new Audio(audio), [audio])
  const membership = useSelector((state) => state?.['user']?.membership)
  const { story_submitted }: any = useSelector<any>(({ story }) => story)
  const onMove = useRef<boolean>(false)
  const barRef = useRef(document.createElement('div'))
  const barAnchor = useRef(document.createElement('div'))
  const barProgress = useRef(document.createElement('div'))
  const popupRef = useRef<HTMLDivElement>(null)
  const [play, setPlay] = useState(false)
  const [showRecordingOption, setShowRecordingOption] = useState(allowRecording)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [playBackRate, setPlayBackRate] = useState({ label: '1.0x (Normal)', value: '1.0' })
  const [isDropOpen, setIsDropOpen] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const onTranslateClose = () => {
    setIsDropOpen(false)
  }
  const onDocumentClick = (e) => {
    if (!popupRef?.current?.contains(e.target) && isDropOpen) {
      setIsDropOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', onTranslateClose)
    document.addEventListener('click', onDocumentClick)
    return () => {
      document.removeEventListener('scroll', onTranslateClose)
      document.removeEventListener('click', onDocumentClick)
    }
  }, [isDropOpen])

  useEffect(() => {
    return () => audioRef && audioRef.pause()
  }, [])

  useEffect(() => {
    setShowRecordingOption(allowRecording)
  }, [allowRecording])

  useEffect(() => {
    audioRef.addEventListener('loadedmetadata', async () => {
      if (audioRef.duration !== Infinity) {
        setDuration(audioRef.duration)
      } else if (!duration) {
        try {
          const totalDuration = await getBlobDuration(audio)
          setDuration(totalDuration)
        } catch (err) {
          setDuration(audioRef.duration)
        }
      }
    })
    audioRef.addEventListener('timeupdate', () => {
      setCurrentTime(audioRef.currentTime)
    })
    audioRef.addEventListener('ended', () => {
      setPlay(false)
      audioRef.pause()
    })
    // mouse move

    return () => {
      // audioRef.pause();
      // audioRef.removeEventListener("canplaythrough", () => {});
      // audioRef.removeEventListener("timeupdate", () => {});
      // audioRef.removeEventListener("ended", () => {});
      // mouse move
    }
  }, [audioRef])

  useEffect(() => {
    audioRef.playbackRate = parseFloat(playBackRate.value)
  }, [playBackRate])

  useEffect(() => {
    if (barProgress.current) {
      const clientRect = barProgress.current.getBoundingClientRect()
      const maxPosX = barProgress.current.clientWidth
      barProgress.current.addEventListener('mousemove', (event) => {
        onMove.current = true
        let posX = event.pageX - clientRect.left
        if (posX < 0) posX = 0
        if (posX > maxPosX) posX = maxPosX
        barAnchor.current.style.left = `${posX - 8}px`
        barAnchor.current.classList.add('anchor-moving')
      })
      barProgress.current.addEventListener('mouseleave', () => {
        onMove.current = false
        barAnchor.current.classList.remove('anchor-moving')
        //move barAnchor
        // const ratio = (currentTime ? currentTime * 100 / duration : 0.5)
        // const width =  ratio / 100 * (barProgress.current.clientWidth)
        // barAnchor.current.style.left = Math.floor(width).toString() + 'px'
      })
    }
    return () => {
      if (barProgress.current) {
        barProgress.current.removeEventListener('mousemove', () => {
          onMove.current = false
          barAnchor.current.classList.remove('anchor-moving')
        })
        barProgress.current.removeEventListener('mouseleave', () => {
          onMove.current = false
          barAnchor.current.classList.remove('anchor-moving')
        })
      }
    }
  }, [barProgress])

  // useEffect(() => {
  //   // add event scroll audio
  //   console.log("add event");
  //   document
  //     .getElementsByClassName(".bar-scroll")[0]
  //     ?.addEventListener("click", function (event) {
  //       alert("Hi!");
  //     });
  //   return () => {
  //     console.log("remove event");
  //     document
  //       .getElementsByClassName(".bar-scroll")[0]
  //       ?.removeEventListener("click", () => {});
  //   };
  // }, []);
  const onRecord = () => {
    setIsOpenModal(true)
  }

  const redirectToAccount = () => {
    // const PlanName = accountTab.Plan
    // history.push(ACCOUNT, PlanName)
    // window.open('https://www.maayot.com/plan-selection', '_blank')
    window.open(PlanChangeLink)
  }

  const onPlay = () => {
    if (!play) {
      audioRef.play()
    } else {
      audioRef.pause()
    }
    setPlay(!play)
  }

  const recordingCancel = (isSubmitted) => {
    setIsOpenModal(false)
    setShowRecordingOption(!isSubmitted)
  }
  // scroll video
  const onScrollAudio = (event) => {
    const clientRect = barProgress.current.getBoundingClientRect()
    const posX = event.pageX - clientRect.left - 8
    const ratio = posX / barProgress.current.clientWidth
    audioRef.currentTime = audioRef.duration * ratio
  }
  // use memo for time audio
  const durationTimeText = useMemo(() => convertAudioTime(duration), [duration])
  const ratio = currentTime ? (currentTime * 100) / duration : 0
  const width = (ratio / 100) * barProgress.current.clientWidth
  barRef.current.style.width = Math.ceil(ratio).toString() + '%'

  if (!onMove.current) {
    barAnchor.current.style.left = Math.floor(width).toString() + 'px'
  }

  const onClose = () => {
    // props.onClose()
    console.log('closed click')
  }

  const onChangeSpeedRate = (elm) => {
    setPlayBackRate(elm)
    setIsDropOpen(false)
  }

  return (
    <>
      <div className='w-row section-audio'>
        <div className='w-col-1 w-col-medium-2 w-col-tiny-1 section-audio-play'>
          <div className='audio-play' onClick={onPlay}>
            <i className={!play ? 'fa fa-play' : 'fa fa-pause'}></i>
          </div>
        </div>
        <div
          className={
            showRecordingOption
              ? 'w-col-10 w-col-medium-10 w-col-tiny-11 section-progress-bar'
              : 'w-col-11 w-col-medium-11 w-col-tiny-12 section-progress-bar'
          }
        >
          <span>{convertAudioTime(currentTime)}</span>
          <div className='audio-progress-bar' ref={barProgress} style={{ position: 'relative' }}>
            <div className='bar' ref={barRef} />
            <div className='hide bar-scroll' onClick={onScrollAudio} />
            <div className='bar-anchor' ref={barAnchor} onClick={onScrollAudio} />
          </div>
          <span>{durationTimeText}</span>
        </div>
        {/* {showRecordingOption ? ( */}
        {
          showRecordingOption && story_submitted?.record_exist === false && !story_submitted?.storyId ? (
            membership?.name === membershipNameType?.Pro || membership?.name === membershipNameType?.Teacher ? (
              <div className='w-col-1 w-col-medium-2 w-col-tiny-1 section-record-btn'>
                <div className='record-audio' onClick={onRecord}>
                  <i className='fa fa-microphone'></i>
                </div>
              </div>
            ) : (
              // ''
              <div className='w-col-1 w-col-medium-2 w-col-tiny-1 section-record-btn'>
                <div className='record-audio-disable' tabIndex={1}>
                  <div id='tooltiptext'>
                    <button className='tooltiptext-close' onClick={onClose}>
                      <i className='fa fa-times' aria-hidden='true'></i>
                    </button>
                    Get your pronunciation and tones corrected daily by a teacher. Only for Pro. <br />
                    <a onClick={redirectToAccount} style={{ textDecoration: 'underline' }}>
                      {' '}
                      Upgrade now
                    </a>
                  </div>
                  <i className='fa fa-microphone'></i>
                </div>
              </div>
            )
          ) : (
            ''
          )
          // (showRecordingOption  ? (
          //   <div className='w-col-1 w-col-medium-2 w-col-tiny-1 section-record-btn'>
          //     <div className='record-audio-disable' >
          //       <i className='fa fa-microphone'></i>
          //     </div>
          //   </div>
          // ) : (
          // ''
          // <div className='w-col-1 w-col-medium-2 w-col-tiny-1 section-record-btn'>
          //   {/* <div className='record-audio-disable' aria-disabled={true} style={{ opacity: 0.5 }}> */}
          //   <div className='record-audio-disable'>
          //     <span className='tooltiptext'>This is Pro version Feature</span>
          //     <i className='fa fa-microphone'></i>
          //   </div>
          // </div>
          // )
        }
        <div onClick={() => setIsDropOpen(false)} className={isDropOpen ? 'overlay_background' : ''}></div>
        <div className='playback_box_speed_main_div'>
          <div className='speed_box' onClick={() => setIsDropOpen(!isDropOpen)}>
            <div className='speed_box_div'>
              <span className='speed_box_text'>{`${playBackRate.value}`}</span>
              {isDropOpen ? <i className='fa fa-chevron-up'></i> : <i className='fa fa-chevron-down'></i>}
            </div>
          </div>
          {isDropOpen && (
            <div
              ref={popupRef}
              className='playback_speed_box_div'
              style={{ position: 'absolute', bottom: 30, zIndex: 99 }}
            >
              <div className='playback_box_speed'>
                <span className='playback_box_speed_heading'>
                  Playback Speed <i onClick={(e) => setIsDropOpen(!isDropOpen)} className='fa fa-close'></i>
                </span>
                {speedList.map((elm, index) => (
                  <span key={index} onClick={() => onChangeSpeedRate(elm)}>
                    {elm.label}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <StoryRecordingModal isOpen={isOpenModal} onCancel={(isSubmitted) => recordingCancel(isSubmitted)} />
    </>
  )
}

export default PLayer
