import React from 'react'
import { Word } from 'containers/story'

interface IStoryTitleProps {
  storyTitleContent?: any
  showPinYin: any
  story: any
  handleClickWord: any
  activeIndex: any
}

const StoryTitle: React.FC<IStoryTitleProps> = (props: any) => {
  const { storyTitleContent, showPinYin, story, activeIndex, handleClickWord } = props

  return (
    <>
      {storyTitleContent?.map((wordObj, index) => {
        const isOldWord = story?.oldWords?.includes(wordObj.word)
        const isIncludePTag =
          wordObj.word.includes('<p>') || wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')
        const stripedWord = wordObj.word.replace(/<[^>]+>/g, '')
        return (
          <React.Fragment key={index}>
            {isIncludePTag && index ? <br /> : ''}
            {stripedWord.length ? (
              <Word
                key={index}
                index={`T${index}`}
                isActive={`T${index}` === activeIndex}
                text={stripedWord}
                textObj={wordObj}
                isNewWord={stripedWord === story?.newWord1 || stripedWord === story?.newWord2}
                isOldWord={isOldWord}
                onClick={handleClickWord}
                pinYinText={showPinYin ? wordObj?.pinyin : null}
              />
            ) : (
              ''
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default StoryTitle
