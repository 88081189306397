import { SET_ERRORS, SET_CORRECTIONS, SET_CURRENT_CORRECTION } from '../types'
import MAAYOT_API, { JSON_HEADER } from './config'
// var tokenize = require("chinese-tokenizer").loadFile('./cedict_ts.u8');

export const setCurrentCorrection = (correction: any) => (dispatch: any) => {
  dispatch({
    type: SET_CURRENT_CORRECTION,
    payload: correction,
  })
}

export const setCorrections = (corrections: any) => (dispatch: any) => {
  dispatch({
    type: SET_CORRECTIONS,
    payload: corrections,
  })
}

export const submitCorrection =
  (storyId: string, memberId: string, correctedAnswer: string, uploadKey: string) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
      const content = {
        memberId,
        storyId,
        correctedRecordingUrl: uploadKey,
        correctedAnswer,
      }
      return fetch(`${MAAYOT_API}story-correction`, {
        method: 'POST',
        headers: JSON_HEADER,
        body: JSON.stringify(content),
      })
        .then((resData) => resData.json())
        .then((data) => {
          if (data && !data.status && Object.keys(data) && Object.keys(data).length > 0) {
            resolve(data)
          } else {
            dispatch({
              type: SET_ERRORS,
              payload: data.message.message,
            })
            reject(data.message.message)
          }
        })
    })
  }

export const getStoryCorrections = (page: number, perPage: number, search: string) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    let queryReq = `${MAAYOT_API}story-corrections?page=${page}&par_page=${perPage}`
    if (search && search !== '') {
      queryReq = `${queryReq}&search=${search}`
    }
    return fetch(queryReq, {
      method: 'GET',
      headers: JSON_HEADER,
    })
      .then((resData) => resData.json())
      .then((data) => {
        if (data && !data.status && Object.keys(data) && Object.keys(data).length > 0) {
          resolve(data)
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: data.message.message,
          })
          reject(data.message.message)
        }
      })
  })
}
