import { logoutUser } from '../../redux/actions/userActions'
import React, { FC, useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import history from '../../browserHistory'
import { membershipNameType, membershipType, PlanChangeLink, storyStep } from '../../shared/constant'
import { ACCOUNT, INTRODUCTION } from 'paths'
import { FONT_SIZE_LIST } from 'components/account/const'
import { SET_CHINESE_WORD_FONT_SIZE } from 'redux/types'
const searchplusIcon = <i className='fa fa-search-plus' />

interface HeaderProps {
  pathName?: any
  story?: any
  step?: any
  membership: any
  authenticated: boolean
}

const HeaderOld: React.FC<HeaderProps> = (props: HeaderProps) => {
  // check access story view at last step
  const { membership } = props
  const dispatch = useDispatch()
  const [isOpen, setOpen] = useState(false)
  const chineseWordFontSize = useSelector((state) => state?.['dashboard']?.chineseWordFontSize)

  let urlRedirectLogo = INTRODUCTION
  if (props.step === storyStep.Step1) {
    urlRedirectLogo = `/story/${props.story?.id}`
  }
  const refMenuMobile = useRef(document.createElement('div'))
  const onMenu = () => {
    if (refMenuMobile) {
      refMenuMobile.current.style.width =
        refMenuMobile.current.style.width === '0px' || !refMenuMobile.current.style.width ? '100%' : '0px'
    }
  }
  const onLogout = () => {
    logoutUser()
  }
  const onOpenAccount = () => {
    refMenuMobile.current.style.width = '0px'
    history.push(ACCOUNT)
  }
  const goToLastAccessStory = () => {
    refMenuMobile.current.style.width = '0px'
    history.push(urlRedirectLogo)
  }
  const accountRoute = '/account'

  const isFree = membership?.name == membershipNameType.Free
  const isPremium = membership?.name == membershipNameType.Premium
  const isStandard = membership?.name == membershipNameType.Standard
  const isSchool = membership?.name == membershipNameType.School
  const isPro = membership?.name == membershipNameType.Pro
  const isTeacher = membership?.name == membershipNameType.Teacher
  const redirectToAccount = () => {
    window.open(PlanChangeLink)
  }

  const onChangeFontSize = (elm) => {
    setOpen(false)
    dispatch({ type: SET_CHINESE_WORD_FONT_SIZE, payload: elm })
  }

  return (
    <div className='navigation-container maayot-header'>
      <div className='container'>
        <div
          data-collapse='medium'
          data-animation='default'
          data-duration='400'
          id='navbar'
          role='banner'
          className='navbar-2 w-nav'
        >
          {props.authenticated && (
            <a onClick={onMenu} className='button-menu-mobile menu-mobile-close nav-menu'>
              <i className='fa fa-bars'></i>
            </a>
          )}

          <Link to={urlRedirectLogo} className='w-nav-brand'>
            <img src='/images/Untitled-12.png' alt='' className='image-40' />
          </Link>
          <div className={isOpen ? 'overlay_div' : ''} onClick={() => setOpen(false)}></div>
          <div className='font-changer-btn-div mob-icon-dropdown'>
            <div className='font-plus-dropdown' onClick={() => setOpen(!isOpen)}>
              <i className='fa fa-search-plus fa-icon-size' />
              {isOpen ? (
                <div className='custom-dropdown-menu'>
                  <ul>
                    {FONT_SIZE_LIST.map((elm, index) => (
                      <li
                        key={index}
                        className={`${chineseWordFontSize?.storyContent === elm?.storyContent && 'active'}`}
                        onClick={() => onChangeFontSize(elm)}
                      >
                        {elm.label}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
          <nav role='navigation' className='nav-menu-wrapper w-nav-menu'></nav>

          <div className='nav-menu-button-wrapper nav-menu-button-large'>
            <div className='nav-functions nav-menu'>
              {/* {!props?.authenticated && (
                <div className='navbar-2 w-nav navbar-button-mobile overlay-content'>
                  <div className='div-block-4'>
                    <button onClick={onLogout} className='button-2 bg-gray-4 login w-button'>
                      Log In
                    </button>
                  </div>
                </div>
              )} */}
              {props.authenticated && (
                <>
                  <div className='m-flx'>
                    <div className='disable-correction-container' tabIndex={1}>
                      <div className={isOpen ? 'overlay_div' : ''} onClick={() => setOpen(false)}></div>
                      <div className='font-changer-btn-div'>
                        <div className='font-plus-dropdown' onClick={() => setOpen(!isOpen)}>
                          <i className='fa fa-search-plus fa-icon-size' />
                          {isOpen ? (
                            <div className='custom-dropdown-menu'>
                              <ul>
                                {FONT_SIZE_LIST.map((elm, index) => (
                                  <li
                                    key={index}
                                    className={`${chineseWordFontSize?.storyContent === elm?.storyContent && 'active'}`}
                                    // className={elm?.value === "18px" ? 'active' : ""}
                                    onClick={() => onChangeFontSize(elm)}
                                  >
                                    {elm.label}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='m-flx'>
                    {(isPro || isStandard || isTeacher) &&
                      (props.pathName !== '/past-stories' ? (
                        <>
                          {!isStandard && !isPro ? (
                            <>
                              <div className='disable-correction-container' tabIndex={1}>
                                <span className='button-2 bg-gray-4 login w-button mr-10 disable-correction-btn'>
                                  Past Stories
                                </span>
                                <div className='disable-correction-tooltip Corrections-tooltip'>
                                  <div className='tooltiptextWriter' id='tooltiptextWriter'>
                                    <button className='tooltiptext-close'>
                                      <i className='fa fa-times' aria-hidden='true'></i>
                                    </button>
                                    Get your speaking and writing corrected daily by a Teacher. Only for Pro. &nbsp;
                                    <a
                                      onClick={redirectToAccount}
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                      Upgrade now
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <a href='/past-stories' className='button-2 bg-green-4 login w-button mr-10'>
                              Past Stories
                            </a>
                          )}
                        </>
                      ) : (
                        <a href='/introduction' className='button-2 bg-gray-4 login w-button mr-10'>
                          Story
                        </a>
                      ))}

                    {/* {props.membership.status === membershipType.Paid && (
                      <a
                        data-ms-membership="5f1cd9369b619b0004e23357"
                        href="#/ms/signup/5f1cd9369b619b0004e23357"
                        className="button-2 bg-gray-4 login w-button"
                      >
                        My Account
                      </a>
                    )} */}
                  </div>
                  {/* <div className='div-block-4'> */}
                  <div className='m-flx'>
                    {(isPro || isStandard) && (
                      <>
                        {props.pathName !== '/correction' ? (
                          <>
                            {isStandard ? (
                              <>
                                <div className='disable-correction-container' tabIndex={1}>
                                  <span className='button-2 bg-gray-4 login w-button mr-10 disable-correction-btn'>
                                    <i
                                      className='fa fa-pencil'
                                      style={{ fontSize: '14px', marginRight: '5px', textDecoration: 'underline' }}
                                    ></i>
                                    Corrections
                                  </span>

                                  <div className='disable-correction-tooltip Corrections-tooltip'>
                                    <div className='tooltiptextWriter' id='tooltiptextWriter'>
                                      <button className='tooltiptext-close'>
                                        <i className='fa fa-times' aria-hidden='true'></i>
                                      </button>
                                      Get your speaking and writing corrected daily by a Teacher. Only for Pro.
                                      <br></br>
                                      <a
                                        onClick={redirectToAccount}
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                      >
                                        Upgrade now
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <a href='/correction' className='button-2 bg-green-4 login w-button mr-10'>
                                <i
                                  className='fa fa-pencil'
                                  style={{ fontSize: '14px', marginRight: '5px', textDecoration: 'underline' }}
                                ></i>
                                Corrections
                              </a>
                            )}
                          </>
                        ) : (
                          <a href='/introduction' className='button-2 bg-gray-4 login w-button mr-10'>
                            Story
                          </a>
                        )}
                        {/* <a href='/streaks' className='button-2 bg-gray-4 login w-button mr-10'>
                          Streaks
                        </a> */}
                      </>
                    )}
                    {isFree && (
                      <>
                        <div className='disable-correction-container' tabIndex={1}>
                          <span className='button-2 bg-gray-4 login w-button mr-10 disable-correction-btn'>
                            Past Stories
                          </span>
                          <div className='disable-correction-tooltip Corrections-tooltip'>
                            <div className='tooltiptextWriter' id='tooltiptextWriter'>
                              <button className='tooltiptext-close'>
                                <i className='fa fa-times' aria-hidden='true'></i>
                              </button>
                              Review the past stories along with their audio and new words. Only for Standard and Pro.
                              &nbsp;
                              <a onClick={redirectToAccount} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                Upgrade now
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className='disable-correction-container' tabIndex={2}>
                          <span className='button-2 bg-gray-4 login w-button mr-10 disable-correction-btn'>
                            <i
                              className='fa fa-pencil'
                              style={{ fontSize: '14px', marginRight: '5px', textDecoration: 'underline' }}
                            ></i>
                            Corrections
                          </span>

                          <div className='disable-correction-tooltip Corrections-tooltip'>
                            <div className='tooltiptextWriter' id='tooltiptextWriter'>
                              <button className='tooltiptext-close'>
                                <i className='fa fa-times' aria-hidden='true'></i>
                              </button>
                              Get your speaking and writing corrected daily by a Teacher. Only for Pro.
                              <br></br>
                              <a onClick={redirectToAccount} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                Upgrade now
                              </a>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {(isFree || isPremium || isStandard || isSchool || isPro) && props.pathName !== accountRoute && (
                      <>
                        <a href={accountRoute} className='button-2 bg-gray-4 login w-button mr-10'>
                          My Account
                        </a>
                      </>
                    )}
                    {isTeacher && (
                      <a href='/dashboard' className='button-2 bg-gray-4 login w-button mr-10'>
                        Dashboard
                      </a>
                    )}
                    {/* {props.membership.status === membershipType.Paid && (
                      <a
                        data-ms-membership="5f1cd9369b619b0004e23357"
                        href="#/ms/signup/5f1cd9369b619b0004e23357"
                        className="button-2 bg-gray-4 login w-button"
                      >
                        My Account
                      </a>
                    )} */}
                  </div>
                  <div className='div-block-4'>
                    <button onClick={onLogout} className='button-2 bg-gray-4 login w-button'>
                      Log Out
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className='menu-button-2 w-nav-button'></div>
          </div>
        </div>
        {/* Nav menu mobile */}
        <div className='nav-menu nav-menu-mobile overlay' ref={refMenuMobile}>
          <div className='nav-menu-mobile-content overlay-content'>
            <div className='navbar-2 w-nav'>
              <a onClick={onMenu} className='button-menu-mobile menu-mobile-close'>
                <i className='fa fa-times'></i>
              </a>
              <a onClick={goToLastAccessStory} className='w-nav-brand'>
                <img src='/images/Untitled-12.png' alt='' className='image-40' />
              </a>
            </div>

            {props.authenticated && (
              <div className='navbar-2 w-nav navbar-button-mobile overlay-content'>
                {/* <div className='div-block-4'>
                  <div className='disable-correction-container' tabIndex={1}>
                    <div className={isOpen ? 'overlay_div' : ''} onClick={() => setOpen(false)}></div>
                    <div className='font-changer-btn-div mob-hide-div'>
                      <button className='button-2 bg-gray-4 login w-button mr-10' onClick={() => setOpen(!isOpen)}>
                        {searchplusIcon}
                        Aa
                      </button>

                      {isOpen ? (
                        <div className='custom-dropdown-menu'>
                          <ul>
                            {FONT_SIZE_LIST.map((elm, index) => (
                              <li
                                key={index}
                                className={`${chineseWordFontSize?.storyContent === elm?.storyContent && 'active'}`}
                                // className={elm?.value === "18px" ? 'active' : ""}
                                onClick={() => onChangeFontSize(elm)}
                              >
                                {elm.label}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div> */}
                <div className='div-block-4'>
                  {(isPro || isStandard || isTeacher) &&
                    (props.pathName !== '/past-stories' ? (
                      <>
                        {!isStandard && !isPro ? (
                          <>
                            <div className='disable-correction-container' tabIndex={1}>
                              <span className='button-2 bg-gray-4 login w-button mr-10 disable-correction-btn'>
                                Past Stories
                              </span>
                              <div className='disable-correction-tooltip Corrections-tooltip'>
                                <div className='tooltiptextWriter' id='tooltiptextWriter'>
                                  <button className='tooltiptext-close'>
                                    <i className='fa fa-times' aria-hidden='true'></i>
                                  </button>
                                  Get your speaking and writing corrected daily by a Teacher. Only for Pro. &nbsp;
                                  <a
                                    onClick={redirectToAccount}
                                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                  >
                                    Upgrade now
                                  </a>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <a href='/past-stories' className='button-2 bg-green-4 login w-button mr-10'>
                            Past Stories
                          </a>
                        )}
                      </>
                    ) : (
                      <a href='/introduction' className='button-2 bg-gray-4 login w-button mr-10'>
                        Story
                      </a>
                    ))}
                </div>
                <div className='div-block-4'>
                  {(isPro || isStandard) &&
                    (props.pathName !== '/correction' ? (
                      <>
                        {isStandard ? (
                          <>
                            <div className='disable-correction-container' tabIndex={1}>
                              <div className='seemore-tooltip1mobile'>
                                <span className='button-2 bg-gray-4 login w-button mr-10 disable-correction-btn'>
                                  <i
                                    className='fa fa-pencil'
                                    style={{ fontSize: '14px', marginRight: '5px', textDecoration: 'underline' }}
                                  ></i>
                                  Corrections
                                </span>

                                <div
                                  className='disable-correction-tooltip Corrections-tooltip'
                                  id='Corrections_tooltip'
                                >
                                  <div className='tooltiptextWriter' id='tooltiptextWriter'>
                                    <button className='tooltiptext-close'>
                                      <i className='fa fa-times' aria-hidden='true'></i>
                                    </button>
                                    Get your speaking and writing corrected daily by a Teacher. Only for Pro.
                                    <br></br>
                                    <a
                                      onClick={redirectToAccount}
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                      Upgrade now
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <a href='/correction' className='button-2 bg-green-4 login w-button mr-10'>
                            <i
                              className='fa fa-pencil'
                              style={{ fontSize: '14px', marginRight: '5px', textDecoration: 'underline' }}
                            ></i>
                            Corrections
                          </a>
                        )}
                      </>
                    ) : (
                      <a href='/introduction' className='button-2 bg-gray-4 login w-button mr-10'>
                        Story
                      </a>
                    ))}
                </div>
                <div className='div-block-4'>
                  {isFree && (
                    <>
                      <div className='disable-correction-container' tabIndex={1}>
                        <div className='seemore-tooltip1mobile'>
                          <span className='button-2 bg-gray-4 login w-button mr-10 disable-correction-btn'>
                            Past Stories
                          </span>

                          <div className='disable-correction-tooltip Corrections-tooltip' id='Corrections_tooltip'>
                            <div className='tooltiptextWriter' id='tooltiptextWriter'>
                              <button className='tooltiptext-close'>
                                <i className='fa fa-times' aria-hidden='true'></i>
                              </button>
                              Review the past stories along with their audio and new words. Only for Standard and Pro
                              <br></br>
                              <a onClick={redirectToAccount} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                Upgrade now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='disable-correction-container' tabIndex={1}>
                        <div className='seemore-tooltip1mobile'>
                          <span className='button-2 bg-gray-4 login w-button mr-10 disable-correction-btn'>
                            <i
                              className='fa fa-pencil'
                              style={{ fontSize: '14px', marginRight: '5px', textDecoration: 'underline' }}
                            ></i>
                            Corrections
                          </span>

                          <div className='disable-correction-tooltip Corrections-tooltip' id='Corrections_tooltip'>
                            <div className='tooltiptextWriter' id='tooltiptextWriter'>
                              <button className='tooltiptext-close'>
                                <i className='fa fa-times' aria-hidden='true'></i>
                              </button>
                              Get your speaking and writing corrected daily by a Teacher. Only for Pro.
                              <br></br>
                              <a onClick={redirectToAccount} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                Upgrade now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className='div-block-4'>
                  {(isFree || isPremium || isStandard || isSchool || isPro) && props.pathName !== accountRoute && (
                    <a href={accountRoute} className='button-2 bg-gray-4 login w-button mr-10'>
                      My Account
                    </a>
                  )}
                </div>
                {/* {props.membership.status === membershipType.Paid && (
                    <a
                      data-ms-membership="5f1cd9369b619b0004e23357"
                      href="#/ms/signup/5f1cd9369b619b0004e23357"
                      className="button-2 bg-gray-4 login w-button"
                    >
                      My Account
                    </a>
                  )} */}
                {isTeacher && (
                  <a href='/dashboard' className='button-2 bg-gray-4 login w-button mr-10'>
                    Dashboard
                  </a>
                )}
                <div className='div-block-4'>
                  <button onClick={onLogout} className='button-2 bg-gray-4 login w-button'>
                    Log Out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  authenticated: state.user.authenticated,
  membership: state.user.membership,
  step: state.story.currentStep,
  story: state.story.currentStory,
  chineseWordFontSize: state.dashboard.chineseWordFontSize,
})
export default connect(mapStateToProps)(HeaderOld)
