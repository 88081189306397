import React from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import CommonCard from 'components/common-card/card'
import UserInfo from 'components/user-info/user-info'
import UpgradeLabel from 'components/upgrade-label'

const Stories = () => {
  const { storiesListLoader, welcomeMessage, CTAMessage }: any = useSelector<any>(({ story }) => story)

  return (
    <Container className='custom_container'>
      <UserInfo title={welcomeMessage} />
      <CommonCard />
      {!storiesListLoader && CTAMessage?.message && <UpgradeLabel />}
    </Container>
  )
}

export default Stories
