import PLayer from 'containers/player/Player'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'

// import { GetStudentCorrection } from '../../redux/actions/studentStoriesAction'
import { getStories, setStories } from '../../redux/actions/pastStoriesAction'
import { getStory, getQuestion } from '../../redux/actions/pastStoriesAction'

import Loader from 'containers/loader/loader'

import { membershipNameType, PlanChangeLink } from 'shared/constant'

import StoriesCard from './StoriesCard'

interface ICorrectionProps {
  credentials?: any
}
const PastStories: React.FC<ICorrectionProps> = (props: any) => {
  const { credentials } = props
  const { customFields, memberId, level } = credentials
  const levelUser = customFields.level || level

  const { membership } = props
  const isStandard = membership?.name == membershipNameType.Standard
  const isPro = membership?.name == membershipNameType.Pro

  // const [audioRecordingUrl, setAudioRecordingUrl] = useState('')
  // const [correctionRecordingUrl, setCorrectionRecordingUrl] = useState('')
  const [firstLineStory, setFirstLineStory] = useState<any>({})
  const [isStoryAvailable, setIsSisStoryAvailable] = useState(false)
  const [loadingMoreStatus, setLoadingMoreStatus] = useState(false)
  const [loadingContentStatus, setLoadingContentStatus] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(5)
  // const [recordingError, setRecordingError] = useState('')
  // const [totalCorrection, setTotalCorrection] = useState(0)
  const [allowRecording, setAllowRecording] = useState(false)

  const [storyDetails, setStoriesDetails] = useState<any>({
    show: false,
    id: '',
  })
  const storiesData: any = useSelector<any>(({ story }) => story.stories)
  const chineseWordFontSize = useSelector((state) => state?.['dashboard']?.chineseWordFontSize)
  const question = props.question

  const redirectToAccount = () => {
    window.open(PlanChangeLink)
  }

  const fetchData = async () => {
    const currentStories = storiesData
    // const currentStories =  []
    const currentPage = page ? page : 1
    const currentPer_Page = perPage
    setPerPage(currentPer_Page)
    const newStories = await props.getStories(currentPer_Page, currentPage, memberId, levelUser) //.then((res) => {
    // setTotalCorrection(newStories.count)
    const allStories = currentStories.concat([...newStories.data])
    props.setStories(allStories)
    setPage(page + 1)
  }

  useEffect(() => {
    if (memberId && levelUser) {
      // props.GetStudentCorrection(memberId)
      Promise.resolve(props.getStories(perPage.toString(), page.toString(), memberId, levelUser)).then(() => {
        setIsSisStoryAvailable(true)
        setPage(page + 1)
      })
    }
  }, [memberId, levelUser])

  useEffect(() => {
    setLoadingContentStatus(true)
    setFirstLineStory({})
    const firstLineWords: string[] = []
    let firstLineBreak = false

    props?.currentStory?.arContent?.forEach((wordObj) => {
      firstLineBreak =
        !firstLineBreak && (wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')) ? true : firstLineBreak

      if (!firstLineBreak) firstLineWords.push({ ...wordObj })

      return wordObj
    })

    setFirstLineStory({
      arContent: firstLineWords,
      storyContent: props?.currentStory.arContentWithTags,
      newWord1: props?.currentStory.newWord1,
      newWord2: props?.currentStory.newWord2,
      oldWords: props?.currentStory.oldWords,
      showPinYin: false,
      arWordSen11: props?.currentStory.arWordSen11,
      arWordSen12: props?.currentStory.arWordSen12,
      arWordSen21: props?.currentStory.arWordSen21,
      arWordSen22: props?.currentStory.arWordSen22,
      pinyinWord1: props?.currentStory.pinyinWord1,
      definition1: props?.currentStory.definition1,
      levelWord1: props?.currentStory.levelWord1,
      pinyinWord2: props?.currentStory.pinyinWord2,
      definition2: props?.currentStory.definition2,
      levelWord2: props?.currentStory.levelWord2,
      storyTitleContent: props?.currentStory?.storyTitleContent,
    })
    setLoadingContentStatus(false)
  }, [props.currentStory])

  // useEffect(() => {
  //   setAudioRecording({})
  //   setAudioRecordingUrl('')
  //   setCorrectionRecordingUrl('')
  //   // setRecordingError('')
  // }, [answersData])

  useEffect(() => {
    storyDetails?.id !== '' &&
      storiesData.forEach((element) => {
        if (element?.id === storyDetails?.id) {
          // console.log('element', element)

          // Change isOpen status
          element.isOpen = storyDetails?.show

          // call Story and question api below
          Promise.all([
            // props.getQuestion(element?.id, element?.level, memberId),
            props.getStory(element?.id, element?.level, memberId),
          ])
        }
      })
  }, [storyDetails?.id])

  const commonSeeMoreButton = () => {
    if (isStandard) {
      return (
        <div className='disable-correction-container' tabIndex={1}>
          <div className='seemore-tooltip'>
            <span className='button-2 bg-gray-4 login w-button mr-10 disable-correction-btn'>See More</span>
            <div className='disable-correction-tooltip'>
              <div className='tooltiptextWriter' id='tooltiptextWriter'>
                <button className='tooltiptext-close'>
                  <i className='fa fa-times' aria-hidden='true'></i>
                </button>
                Review the past 30 days' stories and recordings, only for Pro. &nbsp;
                <a onClick={redirectToAccount} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                  Upgrade now
                </a>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (isPro && storiesData?.length !== 30) {
      return (
        storiesData.length >= perPage === true && (
          <button
            className=''
            onClick={() => {
              setLoadingMoreStatus(true)
              Promise.resolve(fetchData()).then(() => setLoadingMoreStatus(false))
            }}
          >
            Show more{' '}
            <span>
              <i className='fa fa-chevron-down'></i>
            </span>
          </button>
        )
      )
    }
  }

  return (
    <div className='correction-div-main'>
      <div className='section-correction-body  w-container'>
        <div className='section-correction-header'>
          <div className='w-row'>
            <div className='w-col w-col-12 mb-20'>
              <span className='correction-title'> Past Stories </span>
            </div>
          </div>
        </div>

        {isStoryAvailable ? (
          <div className='correction-items'>
            {storiesData?.map((item) => {
              // console.log('item', item)
              return (
                <React.Fragment key={item.id}>
                  <div className='correction-item m-cursor-pointer '>
                    <div className='w-row  w-d-flex section-story-content mb-20 '>
                      <div className='w-col-12 w-col-medium-12 w-col-small-12 w-col-tiny-12 content-left box-padding border-full border-radius-10'>
                        <div className='answer-box box-border'>
                          <div
                            className='correction-item-show'
                            onClick={() => {
                              setLoadingContentStatus(true)
                              setStoriesDetails({
                                show: storyDetails.id === item.id ? !storyDetails.show : true,
                                id: item.id,
                              })
                            }}
                          >
                            <span className='box-title '>
                              <span>{moment(item.last_publication_date * 1000).format('DD MMM YYYY')}</span>
                            </span>
                            <span className='correction-date-downarrow'>
                              <i
                                className={
                                  storyDetails.id === item.id && storyDetails.show
                                    ? 'fa fa-chevron-up'
                                    : 'fa fa-chevron-down'
                                }
                              ></i>
                            </span>
                          </div>
                          {storyDetails.id === item.id &&
                            storyDetails.show &&
                            (loadingContentStatus === false ? (
                              <StoriesCard
                                skritter_token={props?.user_auth_token}
                                user_auth_token={props?.user_auth_token}
                                firstLineStory={firstLineStory}
                                chineseWordFontSize={chineseWordFontSize}
                              />
                            ) : (
                              <Loader />
                            ))}
                        </div>

                        <div className='opencorrection-s1'>
                          {storyDetails.id === item.id && storyDetails.show && loadingContentStatus === false && (
                            <>
                              <div className='w-row  w-d-j-s-flex section-story-content correction-withoutTop-padding'>
                                <div className='w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-12 content-left box-padding'>
                                  <div className='correction-answer-box'>
                                    <span className='box-title mb-10'>Audio</span>
                                    {item?.id && item?.audio !== '' && item?.audio ? (
                                      <>
                                        <div className='w-row w-col-11 w-col-medium-11 w-col-small-12 w-col-tiny-12 box-audio'>
                                          <PLayer audio={item?.audio}></PLayer>
                                        </div>
                                      </>
                                    ) : (
                                      'Audio Not Found'
                                    )}
                                  </div>
                                </div>
                                <div className='w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-12 content-left'>
                                  <div className='correction-box'>
                                    <div className='w-row w-col-12 w-col-medium-11 w-col-small-12 w-col-tiny-12 box-audio'>
                                      {/* {item?.correctedRecordingUrl ? (
                                          <>
                                            <span className='box-title'> Teacher Correction </span>
                                            {!answersData?.isOpen &&
                                            correctionRecordingUrl !== '' &&
                                            item?.correctedRecordingUrl ? (
                                              <>
                                                <PLayer audio={correctionRecordingUrl}></PLayer>
                                              </>
                                            ) : (
                                              'Audio Not Found'
                                            )}
                                          </>
                                        ) : (
                                          ''
                                        )} */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr color='#f4f4f4' />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            })}
            {loadingMoreStatus === true && <Loader />}
            <div
              className='see-more-btn text-center'
              // onClick={() => {
              //   setLoadingMoreStatus(true)
              //   Promise.resolve(fetchData()).then(() => setLoadingMoreStatus(false))
              // }}
            >
              {commonSeeMoreButton()}
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  story: state.pastStories.currentStory,
  user_auth_token: state.user.user_auth_token,
  credentials: state.user.credentials,
  membership: state.user.membership,
  currentStory: state.pastStories.currentStory,
  question: state.corrections.question,
})
const mapActionsToProps = {
  getStories,
  getStory,
  getQuestion,
  setStories,
}
export default connect(mapStateToProps, mapActionsToProps)(PastStories)
