import '../modal.css'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { pinyinSettingOptions } from 'shared/constant'
import { updatePinyinSetting } from 'redux/actions/storyAction'
// import { SET_PINYIN_SETTINGS } from 'redux/types'

interface PinyinSettingModal {
  closePinyinSetting?: any
  openPinyinSetting?: boolean
}

const PinyinSettingModal = ({ closePinyinSetting, openPinyinSetting }) => {
  const { credentials }: any = useSelector<any>(({ user }) => user)
  const { pinyin_settings }: any = useSelector<any>(({ story }) => story)
  const [pinyinState, setPinyinState] = useState({ ...pinyin_settings })

  const handle = {
    onChange: ({ name, value }) => {
      setPinyinState({
        ...pinyinState,
        [name]: value,
      })
    },
    onSubmit: async () => {
      if (credentials?.memberId) {
        await updatePinyinSetting({ data: pinyinState, memberId: credentials?.memberId }).then(({ data }) => {
          if (data) {
            closePinyinSetting(false)
          }
        })
      }
    },
  }

  return (
    <div className='custom_modal'>
      <Modal
        show={openPinyinSetting}
        onHide={() => closePinyinSetting(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='common_modal_main_div'
      >
        <Modal.Body className='mobile_align_div'>
          <div className='modal_title_div'>
            <h4 className='modal_heading'>Pinyin Settings</h4>
            <div className='modal_close_icon_div' onClick={() => closePinyinSetting(false)}>
              <img className='' alt={'icon'} src={'/images/close-icon-black.svg'} />
            </div>
          </div>
          <div className='Pinyin_action_main_div'>
            <div className='Pinyin_action_box'>
              <p className='event_title_text'>New word</p>
              <div className='Pinyin_btn_group btn-group'>
                {pinyinSettingOptions?.map((ele, i) => (
                  <button
                    key={i}
                    className={`btn btn_cls ${pinyinState?.new_word === ele?.value ? 'pinyin_btn_active' : ''} `}
                    onClick={() => handle.onChange({ name: 'new_word', value: ele?.value })}
                  >
                    {ele?.label}
                  </button>
                ))}
              </div>
            </div>
            <div className='Pinyin_action_box'>
              <p className='event_title_text'>Recently learned word</p>
              <div className='Pinyin_btn_group btn-group'>
                {pinyinSettingOptions?.map((ele, i) => (
                  <button
                    key={i}
                    className={`btn btn_cls ${
                      pinyinState?.recently_learned_word === ele?.value ? 'pinyin_btn_active' : ''
                    } `}
                    onClick={() => handle.onChange({ name: 'recently_learned_word', value: ele?.value })}
                  >
                    {ele?.label}
                  </button>
                ))}
              </div>
            </div>
            <div className='Pinyin_action_box'>
              <p className='event_title_text'>Known word</p>
              <div className='Pinyin_btn_group btn-group'>
                {pinyinSettingOptions?.map((ele, i) => (
                  <button
                    key={i}
                    className={`btn btn_cls ${pinyinState?.known_word === ele?.value ? 'pinyin_btn_active' : ''} `}
                    onClick={() => handle.onChange({ name: 'known_word', value: ele?.value })}
                  >
                    {ele?.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className='text-center modal_btn_div'>
            <button className='common-btn-cls green-btn-class modal_btn_width mt-0' onClick={handle.onSubmit}>
              Save and apply
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default PinyinSettingModal
