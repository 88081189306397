import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import {
  getStory,
  getStoryRecording,
  setStep,
  saveLastStep,
  setPreventRedirect,
  setShowRecordingSuccess,
  translate,
  wordDoesNotKnow,
  wordDoKnow,
  getWordDetail,
} from '../../redux/actions/storyAction'
import { converterType, MemberStepInStory, storyStep, wordType } from '../../shared/constant'
import NavigationFooter from '../../containers/navigation-footer/NavigationFooter'
import { INTRODUCTION, QUIZ } from 'paths'
import Loader from '../../containers/loader/loader'
import history from '../../browserHistory'
import { characterConst } from '../../shared/constant'
import StoryIntro from 'containers/storyIntro'
import Translate from 'containers/popup/Translate'
import StoryTitle from './StoryTitle'
import { AddWordToHackChinese, handleAddWordToSkritter } from 'redux/actions/userActions'
import { getQuiz, getGeneralResult } from '../../redux/actions/quizAction'
import WithRedirect from 'containers/WithRedirect'
import { REGEX } from 'utils/regex'
import WordDetail from 'components/word-detail'
import { converterWord, getWordLine, getWordType } from 'utils/helperFunction'
import PinyinSettingModal from 'components/modal/pinyin-settings-modal'
import WordExamplesModal from 'components/modal/word-examples-modal'
import SettingIcon from 'components/icon-svg/SettingIcon'
import { toUpperFirstLetter } from 'utils/utils'

const eyeIcon = <i className='fa fa-eye' />
const eyeSlashIcon = <i className='fa fa-eye-slash' />

const Story: React.FC<any> = ({
  saveLastStep,
  storyId,
  lastStep,
  lastStepEnum,
  setPreventRedirect,
  isGetData,
  showRecordingSuccess,
  storyLoading,
  storyLevel,
  story,
  user_auth_token,
  skritter_token,
  credentials,
  quizDetail,
  errors,
  getQuiz,
  getStoryRecording,
}) => {
  const { memberId } = credentials
  const chineseWordFontSize = useSelector((state) => state?.['dashboard']?.chineseWordFontSize)
  const popupRef = useRef<HTMLInputElement>(null)
  const contentStoryRef = useRef(document.createElement('div'))
  const extraDivRef = useRef<HTMLDivElement>(null)
  const contentWordRef = useRef<HTMLDivElement>(null)
  const [showPinYin, setShowPinYin] = useState(false)
  const [allowRecording, setAllowRecording] = useState(false)
  const [transVisible, setTransVisible] = useState(false)
  const [transData, setTransData] = useState({})
  const [activeIndex, setActiveIndex] = useState('-1')
  const [storyWord, setStoryWord] = useState([...story?.storyTitleContent, ...story?.storyContent])
  const [openPinyinSetting, closePinyinSetting] = useState(false)
  const [wordDetailVisible, setWordDetailVisible] = useState(false)
  const [openExampleModal, setOpenExampleModal] = useState(false)
  const [isImportanceOpen, setImportanceOpen] = useState(false)
  const [isMasteryOpen, setMasteryOpen] = useState(false)

  useEffect(() => {
    document.addEventListener('scroll', onTranslateClose)
    document.addEventListener('click', onDocumentClick)
    return () => {
      document.removeEventListener('scroll', onTranslateClose)
      document.removeEventListener('click', onDocumentClick)
    }
  }, [contentStoryRef, story])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (contentWordRef.current && !contentWordRef.current.contains(event.target as Node)) {
        setWordDetailVisible(false)
        setImportanceOpen(false)
        setMasteryOpen(false)
        setActiveIndex('-1')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleTranslate = (event, sentance) => {
    if (
      (event.type === 'mouseover' || event.type === 'click') &&
      event.target.innerText &&
      event.target.innerText.length > 0
    ) {
      trans(event.target.innerText, sentance, event.clientY, event.clientX)
    }
  }

  const getWordTypeFromStory = (word: string) => {
    const newWord = story?.newWordsArray?.find((wordDetail) => word === converterWord(wordDetail?.word))
    const learningWord = story?.learningWords?.find((wordDetail) => word === converterWord(wordDetail?.word))
    const growingWord = story?.growingWords?.find((wordDetail) => word === converterWord(wordDetail?.word))
    const developingWord = story?.developingWords?.find((wordDetail) => word === converterWord(wordDetail?.word))
    const masteredWord = story?.masteredWords?.find((wordDetail) => word === converterWord(wordDetail?.word))
    const type = getWordType(newWord, learningWord, growingWord, developingWord, masteredWord)
    return type
  }

  const handleClickWord = (event, word, sentance, key) => {
    if (typeof word !== 'string' && !REGEX.specialChars.test(word?.word)) {
      const storyWords = storyWord
        .filter?.((wordInfo) => {
          if (wordInfo?.word.includes('<p>') || wordInfo?.word.includes('</p>') || wordInfo?.word.includes('</p><p>')) {
          } else {
            return wordInfo
          }
        })
        ?.filter((wordInfo: any) => wordInfo?.word !== word?.word)
      const mastery = getWordTypeFromStory(word?.word)
      wordDoesNotKnow({ word: word?.word, memberId, storyId, mastery: toUpperFirstLetter(mastery) })
      setStoryWord(storyWords)
      handleTranslate(event, sentance)
    }
    setActiveIndex(activeIndex === key ? `-1` : key)
    event.preventDefault()
    event.stopPropagation()
  }

  const setTranslate = async (word: string, sentance: any, posTop: number, posLeft: number, data: any) => {
    if (story?.is_recommended) {
      const sentanceString = getWordLine(sentance)
      if (word && sentanceString && memberId) {
        setWordDetailVisible(true)
        const mastery = getWordTypeFromStory(word)
        await getWordDetail({
          payload: { word, memberId, sentence: sentanceString },
          wordType: toUpperFirstLetter(mastery),
        })
      }
      return
    } else {
      const newWord1 = !story?.newWord1 ? '' : converterWord(story?.newWord1)
      const newWord2 = !story?.newWord2 ? '' : converterWord(story?.newWord2)
      const isOldWord = story?.oldWords?.includes(word)

      const type =
        newWord1 === word || newWord2 === word ? wordType.NewWord : isOldWord ? wordType.OldWord : wordType.Word
      setTransVisible(true)
      setTransData({
        type,
        top: posTop,
        left: posLeft,
        word,
        definition: data.pinyin,
        example1: data.english,
        example2: '',
        level: 'HSK 5',
      })
    }
  }

  const trans = (word: string, sentance: any, posTop: number, posLeft: number) => {
    const allDataOp = [...story?.arContent, ...story?.storyTitleContent]
    if (/^[&.-”“，、；？@!#$%^&*()]+$/.test(word) || word.trim() === '。') {
      setTransVisible(false)
      return
    }
    const wordTranslate = allDataOp.filter(
      (iword) =>
        (globalThis.characterPreference === characterConst.traditional
          ? converterType.converterTraditional(iword.word)
          : converterType.converterSimplified(iword.word)) === word,
    )
    if (wordTranslate && wordTranslate.length > 0) {
      setTranslate(word, sentance, posTop, posLeft, wordTranslate[0])
      if (user_auth_token) {
        AddWordToHackChinese(wordTranslate[0], user_auth_token)
      }
      if (skritter_token) {
        handleAddWordToSkritter({
          word,
          translate: wordTranslate[0],
          title: story?.storyTitleContent,
          skritter_token: skritter_token,
        })
      }
    } else {
      translate(word)
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          if (data && data.length > 0) {
            setTranslate(word, sentance, posTop, posLeft, data[0])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const onTranslateClose = () => {
    setTransVisible(false)
  }
  const onDocumentClick = (e) => {
    if (!popupRef?.current?.contains(e.target)) {
      setTransVisible(false)
    }
  }

  useEffect(() => {
    if (JSON.stringify(story) === '{}') {
      getStory(storyId, storyLevel, memberId)
      if (!quizDetail?.quiz) {
        getQuiz(storyId, storyLevel, memberId).then((result) => {
          if (result.memberAnswer) {
            getGeneralResult(storyId)
          }
        })
      }
    }

    getRecording()
  }, [isGetData, storyId, storyLevel, memberId])

  useEffect(() => {
    window.scrollTo(0, 0)
    setStep(storyStep.Step1)
    // getRecording()
  }, [])

  useEffect(() => {
    if (showRecordingSuccess) {
      setTimeout(() => setShowRecordingSuccess(false), 3000)
    }
  }, [showRecordingSuccess])

  useEffect(() => {
    if (
      storyId &&
      storyLevel &&
      memberId &&
      lastStep !== MemberStepInStory.STORY_AND_LISTENING &&
      (lastStepEnum || lastStepEnum === 0) &&
      lastStepEnum < storyStep.Step1
    ) {
      saveLastStep(MemberStepInStory.STORY_AND_LISTENING, storyLevel, memberId, storyId)
    }
  }, [storyId, storyLevel, memberId])

  const getRecording = () => {
    // if (membershipPlan === membershipNameType.Pro && storyId && memberId) {
    if (storyId && memberId) {
      getStoryRecording(storyId, memberId)
        .then((res) => {
          setAllowRecording(!res?.record_exist)
        })
        .catch(() => setAllowRecording(false))
    }
  }

  const handleChangeChk = () => {
    setShowPinYin(!showPinYin)
  }

  const icon = useMemo(() => {
    return showPinYin ? eyeSlashIcon : eyeIcon
  }, [showPinYin])

  const audio = useMemo(() => story.audio, [story])
  const backOnClick = (e) => {
    setPreventRedirect(true)
    history.push(`${INTRODUCTION}`)
  }

  const onNext = () => {
    const uniqueItems = {}
    storyWord.forEach((item) => {
      uniqueItems[item.word] = item
    })
    const wordList = Object.values(uniqueItems)
      ?.filter((wordInfo: any) => !REGEX.specialChars.test(wordInfo?.word))
      .map((wordInfo: any) => wordInfo?.word)
    wordDoKnow({ wordList, storyId, memberId })
    history.push(`${QUIZ}/${storyId}`)
  }

  return (
    <>
      <div ms-membership='free' className='section-2'>
        <div className='container mb-0'>
          <div className='w-row  introduction-main-div mob_padding_lr'>
            {/* <div className='w-row m-flx m-sp-bw align-center introduction-main-div'> */}
            {JSON.stringify(story) !== '{}' && (
              <div className='w-col-12 w-col-medium-12 w-col-small-12 w-col-tiny-12  pinyin-button'>
                <div className=''>
                  <p
                    // className='button-2 bg-light-gray w-button'
                    onClick={() => {
                      // handleChangeChk()
                      closePinyinSetting(true)
                    }}
                    className='action_btn_text d-flex align-items-center gap-2'
                  >
                    <SettingIcon color={'#373737'} />
                    Pinyin Setting
                    {/* {showPinYin ? ` Hide PinYin` : ` Show PinYin`} */}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='story_sec_container'>
          <div className='w-col-12 w-col-medium-12 w-col-small-12 w-col-tiny-12  content-left mobile_remove_space_title mob_padding_lr'>
            <span
              style={{
                fontSize: chineseWordFontSize?.storyTitle,
                lineHeight: 'initial',
                letterSpacing: '1.5px',
                display: 'flex',
                flexWrap: 'wrap',
              }}
              className='introduction-title mob_linelight_remove'
            >
              {
                story?.storyTitle && (
                  // (globalThis.characterPreference === characterConst.traditional ? (
                  //   <StoryTitle
                  //     storyTitleContent={story?.storyTitleContent}
                  //     showPinYin={showPinYin}
                  //     story={story}
                  //     activeIndex={activeIndex}
                  //     handleClickWord={handleClickWord}
                  //   />
                  // ) : (
                  <StoryTitle
                    storyTitleContent={story?.storyTitleContent}
                    showPinYin={showPinYin}
                    story={story}
                    activeIndex={activeIndex}
                    handleClickWord={handleClickWord}
                  />
                )
                // )
              }
            </span>
          </div>
        </div>

        <div className='divider_line_story'></div>

        <div className='section-story story_sec_container'>
          <div className='mob_padding_lr'>
            {showRecordingSuccess ? (
              <div className='recording-submitted-popup'>
                <h6>Your recording has been submitted</h6>
              </div>
            ) : (
              ''
            )}

            {JSON.stringify(story) !== '{}' ? (
              <>
                {(errors === 'Error' || errors === null) && story && (
                  <>
                    {/* <div className='w-nav story-nav-content'></div> */}

                    <div className='w-row section-story-content'>
                      <StoryIntro
                        story={story}
                        showPinYin={showPinYin}
                        handleClickWord={handleClickWord}
                        activeIndex={activeIndex}
                        chineseWordFontSize={chineseWordFontSize}
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              <React.Fragment>
                {storyLoading ? (
                  <Loader />
                ) : (
                  <div className='item-title' style={{ textAlign: 'center' }}>
                    No translation is found
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <>
        <NavigationFooter
          audio={audio}
          backOnclick={backOnClick}
          nextPath={`${QUIZ}/${storyId}`}
          nextText={'Next Step'}
          allowRecording={allowRecording}
          nextOnclick={onNext}
        />
      </>
      <PinyinSettingModal closePinyinSetting={closePinyinSetting} openPinyinSetting={openPinyinSetting} />
      <WordDetail
        ref={contentWordRef}
        memberId={memberId}
        setOpenExampleModal={setOpenExampleModal}
        isImportanceOpen={isImportanceOpen}
        setImportanceOpen={setImportanceOpen}
        isMasteryOpen={isMasteryOpen}
        setMasteryOpen={setMasteryOpen}
        setWordDetailVisible={setWordDetailVisible}
        setActiveIndex={setActiveIndex}
        visible={wordDetailVisible}
      />
      <WordExamplesModal
        ref={extraDivRef}
        openExampleModal={openExampleModal}
        setOpenExampleModal={setOpenExampleModal}
        setWordDetailVisible={setWordDetailVisible}
      />
      <Translate ref={popupRef} visible={transVisible} data={transData} onClose={onTranslateClose} />
    </>
  )
}

const mapStateToProps = (state: any, ownProps: any) => ({
  user_auth_token: state.user.user_auth_token,
  skritter_token: state.user.skritter_token,
  credentials: state.user.credentials,
  membershipPlan: state.user.membership.name,
  membership: state.user.membership,
  story: state.story.currentStory,
  storyLoading: state.story.storyLoading,
  errors: state.UI.errors,
  storyLevel: state.story.storyLevel,
  storyId: ownProps.match.params.id,
  lastStep: state.story.lastStep,
  lastStepEnum: state.story.lastStepEnum,
  showRecordingSuccess: state.story.showRecordingSuccess,
  isGetData:
    (state.user.credentials.level.length > 0 || state.user.credentials.customFields?.level.length > 0) &&
    !state.story.errors &&
    (JSON.stringify(state.story.currentStory) === '{}' || state.story.currentStory.id !== ownProps.match.params.id),
  quizDetail: state.quiz.quizDetail,
})

const mapActionsToProps = {
  getStory,
  getStoryRecording,
  setStep,
  saveLastStep,
  setPreventRedirect,
  setShowRecordingSuccess,
  getQuiz,
  getGeneralResult,
}
export default connect(mapStateToProps, mapActionsToProps)(WithRedirect(Story))
