import moment from 'moment-timezone'
import React, { useState } from 'react'
import Countdown from 'react-countdown'
import { countDownLabel, countDownCheckTime, milisInDay, countDownType, milisInWeek } from '../../shared/constant'
import { momentToMilis } from '../../utils/utils'
import { useSelector } from 'react-redux'
import { getCurrentStories } from 'redux/actions/storyAction'

interface ICountdownProps {
  // level: string;
  type?: countDownType
  color?: string
}

const CountDown: React.FC<ICountdownProps> = (props: ICountdownProps) => {
  // check time countdown with GMT +0 and local timezone
  const { credentials, membership }: any = useSelector<any>(({ user }) => user)
  const memberId = credentials?.memberId
  const level = credentials?.customFields?.level
  const planName = membership?.name
  const currentDay = moment.utc()
  let timeCDMilis = milisInDay - momentToMilis(currentDay)
  if (props.type === countDownType.Week && currentDay.weekday() != 7) {
    timeCDMilis = milisInWeek - currentDay.weekday() * milisInDay - momentToMilis(currentDay)
  }
  const [stopTimer, setStopTimer] = useState(true)
  let typeLabel = !props.color && countDownLabel.Green
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    formatted,
    completed,
  }: {
    days: any
    hours: any
    minutes: any
    seconds: any
    formatted: any
    completed: boolean
  }) => {
    if (completed) {
      if (stopTimer && memberId && level) {
        setStopTimer(false)
        getCurrentStories(memberId, level, planName)
      }
      // return <h1>Countdown complete</h1>
    } else {
      setStopTimer(true)
      typeLabel = !props.color && countDownLabel.Green
      // Check time to render countdown color
      if (hours * 60 + minutes <= countDownCheckTime.Red) {
        typeLabel = !props.color && countDownLabel.Red
      } else if (hours * 60 + minutes <= countDownCheckTime.Orange) {
        typeLabel = !props.color && countDownLabel.Orange
      }
    }
    // Render a countdown
    return (
      <div className='countdown'>
        {props.type === countDownType.Week && (
          <>
            <div className='w-row block-countdown'>
              <div className='w-col count-down-text level-week'>{formatted?.days}</div>
              <div className='level-week-text'>Day</div>
            </div>
            &nbsp;
            <div className='w-row block-countdown'>
              <div className='w-col count-down-text level-week'>{formatted?.hours}</div>
              <div className='level-week-text'>Hour</div>
            </div>
            &nbsp;
            <div className='w-row block-countdown'>
              <div className='w-col count-down-text level-week'>{formatted?.minutes}</div>
              <div className='level-week-text'>Min</div>
            </div>
            &nbsp;
            <div className='w-row block-countdown'>
              <div className='w-col count-down-text level-week'>{formatted?.seconds}</div>
              <div className='level-week-text'>Sec</div>
            </div>
          </>
        )}
        {props.type === countDownType.Day && (
          <>
            <div className={`count-down-text level-${typeLabel}`}>{formatted?.hours}</div>
            &nbsp;
            <div className={`text-dots-mark text-${typeLabel}`}>:</div>&nbsp;
            <div className={`count-down-text level-${typeLabel}`}>{formatted?.minutes}</div>
            &nbsp;
            <div className={`text-dots-mark text-${typeLabel}`}>:</div>&nbsp;
            <div className={`count-down-text level-${typeLabel}`}>{formatted?.seconds}</div>
          </>
        )}
      </div>
    )
  }

  return <Countdown date={Date.now() + timeCDMilis} renderer={renderer} />
}

export default CountDown
