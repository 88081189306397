import {
  MANAGE_WORDLIST_LOADER,
  RESET_ONBOARDING,
  SET_ALL_LANGUAGE,
  SET_ALL_WORDS,
  // SET_ISFREETRIAL,
  SET_ISLEADUSER,
  SET_IS_RESULT,
  SET_LANGUAGE_INFO,
  SET_LEARN_ABOUT,
  SET_LEARN_ABOUT_ANSWER,
  SET_LEARN_ABOUT_LOADING,
  SET_LEARN_ABOUT_TITLE,
  SET_LEARN_ABOUT_TYPE,
  SET_STEPPER_STEP,
  SET_USERTYPE,
  SET_WORDSLIST,
  SET_WORD_RESULT,
} from 'redux/types'

interface IStoryState {
  languages: any
  learnAbout: string[]
  learnAboutLoading: boolean
  learnAboutType: string
  learnAboutTitle: any
  learnAboutAnswer: string
  languageInfo: any
  wordDetail: any
  wordResult: any
  is_result: boolean
  stepper_step: number
  tempUser: string
  preferenceType: string
  wordList_loader: boolean
  userType: string
  isFreeTrial: boolean
  isLeadUser: boolean
}

const initialState: IStoryState = {
  languages: [],
  learnAbout: [],
  learnAboutLoading: false,
  learnAboutType: '',
  learnAboutTitle: null,
  learnAboutAnswer: '',
  languageInfo: null,
  wordDetail: null,
  wordResult: null,
  is_result: false,
  stepper_step: 1,
  tempUser: '',
  preferenceType: '',
  wordList_loader: false,
  userType: '',
  isFreeTrial: true,
  isLeadUser: false,
}

export default function onBoardingReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_LEARN_ABOUT:
      return {
        ...state,
        learnAbout: action?.payload || [],
      }
    case SET_LEARN_ABOUT_LOADING:
      return {
        ...state,
        learnAboutLoading: action?.payload || false,
      }
    case SET_LEARN_ABOUT_TYPE:
      return {
        ...state,
        learnAboutType: action?.payload || '',
      }
    case SET_LEARN_ABOUT_TITLE:
      return {
        ...state,
        learnAboutTitle: action?.payload || null,
      }
    case SET_LEARN_ABOUT_ANSWER:
      return {
        ...state,
        learnAboutAnswer: action?.payload || '',
      }
    case SET_ALL_LANGUAGE:
      return {
        ...state,
        languages: action?.payload,
      }
    case SET_LANGUAGE_INFO:
      return {
        ...state,
        languageInfo: action?.payload,
      }
    case SET_ALL_WORDS:
      return {
        ...state,
        wordDetail: action?.payload?.wordList,
        tempUser: action?.payload?.wordList?.tempUser,
        preferenceType: action?.payload?.preferenceType,
      }
    case SET_WORDSLIST:
      return {
        ...state,
        wordDetail: {
          ...state.wordDetail,
          wordList: action?.payload,
          tempUser: action?.payload?.tempUser,
        },
      }
    case SET_WORD_RESULT:
      return {
        ...state,
        wordResult: action?.payload,
        wordList: [],
      }
    case SET_IS_RESULT:
      return {
        ...state,
        is_result: action?.payload,
      }
    case SET_STEPPER_STEP:
      return {
        ...state,
        stepper_step: action?.payload,
      }
    case RESET_ONBOARDING:
      return {
        ...initialState,
      }
    case SET_USERTYPE:
      return {
        ...state,
        userType: action?.payload,
      }
    case MANAGE_WORDLIST_LOADER:
      return {
        ...state,
        wordList_loader: action?.payload,
      }
    // case SET_ISFREETRIAL:
    //   return {
    //     ...state,
    //     isFreeTrial: action?.payload,
    //   }
    case SET_ISLEADUSER:
      return {
        ...state,
        isLeadUser: action?.payload,
      }
    default:
      return state
  }
}
