import { characterConst, converterType } from 'shared/constant'

export const converterWord = (word) =>
  globalThis.characterPreference === characterConst.traditional
    ? converterType.converterTraditional(word)
    : converterType.converterSimplified(word)

export const getWordType = (newWord, learningWord, growingWord, developingWord, masteredWord) => {
  return newWord
    ? 'new'
    : learningWord
    ? 'learning'
    : growingWord
    ? 'growing'
    : developingWord
    ? 'developing'
    : masteredWord
    ? 'mastered'
    : 'word'
}

export const getWordLine = (wordArray) => {
  return wordArray
    ?.map((wordInfo) => wordInfo?.word)
    ?.toString()
    ?.replace(/<\/?p>/g, '')
    ?.replace(/,/, '')
    ?.replace(/,/g, '')
    ?.trim()
}

export const getWordTypeClass = (wordType) => {
  const key = wordType?.toLowerCase()
  switch (key) {
    case 'new':
      return ['new_bg_color', '', '', '', '']
    case 'learning':
      return ['learning_bg_color', 'learning_bg_color', '', '', '']
    case 'growing':
      return ['growing_bg_color', 'growing_bg_color', 'growing_bg_color', '', '']
    case 'developing':
      return ['developing_bg_color', 'developing_bg_color', 'developing_bg_color', 'developing_bg_color', '']
    case 'mastered':
      return new Array(5).fill('mastered_bg_color')

    default:
      return new Array(5).fill('')
  }
}
