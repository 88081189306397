import store from 'redux/stores'
import {
  CURRENT_STREAK_LOADING,
  LONGEST_STREAK_LOADING,
  SET_LONGEST_STREAK,
  SET_CURRENT_STREAK,
  CURRENT_MONTH_STREAK_LOADING,
  SET_MONTH_STREAK,
} from '../types'
import MAAYOT_API, { JSON_HEADER } from './config'

const getCompletedStreakAPI = (memberId: number) => {
  return fetch(`${MAAYOT_API}tracking/streak/completed?memberId=${memberId}`, {
    method: 'GET',
    headers: JSON_HEADER,
  }).then((resData) => resData.json())
}

const getLongestStreakAPI = (memberId: number) => {
  return fetch(`${MAAYOT_API}tracking/streak/longest?memberId=${memberId}`, {
    method: 'GET',
    headers: JSON_HEADER,
  }).then((resData) => resData.json())
}

const getCurrentStreakAPI = (memberId: number) => {
  return fetch(`${MAAYOT_API}tracking/streak/current?memberId=${memberId}`, {
    method: 'GET',
    headers: JSON_HEADER,
  })
    .then((resData) => resData && resData?.json())
    .catch((err) => console.log('err', err))
}

const getStreakByMonthAPI = (memberId: number, month: string, year: string) => {
  return fetch(`${MAAYOT_API}tracking/streak/monthly?memberId=${memberId}&month=${year}-${month}`, {
    method: 'GET',
    headers: JSON_HEADER,
  }).then((resData) => {
    return resData.json()
  })
}

export const getCompletedStreak = (memberId: number) => (dispatch: any) => {
  if (!memberId) {
    return Promise.reject('Invalid Params')
  }
  return getCompletedStreakAPI(memberId)
}

export const getLongestStreak = (memberId: number) => (dispatch: any) => {
  if (!memberId) {
    return Promise.reject('Invalid Params')
  }
  dispatch({
    type: LONGEST_STREAK_LOADING,
  })
  getLongestStreakAPI(memberId)
    .then((res) => {
      dispatch({
        type: SET_LONGEST_STREAK,
        payload: res?.data || {},
      })
    })
    .catch((e) => {
      Promise.reject(e)
    })
}

export const getCurrentStreak = (memberId: number) => {
  if (!memberId) {
    return Promise.reject('Invalid Params')
  }
  store.dispatch({
    type: CURRENT_STREAK_LOADING,
  })
  getCurrentStreakAPI(memberId)
    .then((res) => {
      store.dispatch({
        type: SET_CURRENT_STREAK,
        payload: res || {},
      })
    })
    .catch((e) => {
      Promise.reject(e)
    })
}
export const getStreakByMonth = (memberId: number, month: string, year: string) => (dispatch: any) => {
  if (!memberId) {
    return Promise.reject('Invalid Params')
  }
  dispatch({
    type: CURRENT_MONTH_STREAK_LOADING,
  })
  getStreakByMonthAPI(memberId, month, year)
    .then((res) => {
      const result = res?.data || []
      dispatch({
        type: SET_MONTH_STREAK,
        payload: { dates: [...result], month, year },
      })
    })
    .catch((e) => {
      Promise.reject(e)
    })
}
