import React, { useState } from 'react'
import Parser from 'html-react-parser'
import { extractDefinition, toUpperFirstLetter } from 'utils/utils'
import { getWordTypeClass } from 'utils/helperFunction'
import { wordClick } from 'redux/actions/storyAction'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

interface IWordDetailProps {
  visible?: boolean
  memberId?: string
}
const downArrow = <i className='fa fa-angle-down' />
const upArrow = <i className='fa fa-angle-up' />

const WordDetail = React.forwardRef<HTMLInputElement, IWordDetailProps>((props: any, ref: any) => {
  const {
    visible,
    setOpenExampleModal,
    memberId,
    isMasteryOpen,
    setMasteryOpen,
    isImportanceOpen,
    setImportanceOpen,
    setWordDetailVisible,
    setActiveIndex,
  } = props
  const { id }: any = useParams()
  const { wordDetailLoader, wordDetails }: any = useSelector<any>(({ story }) => story)
  const [expandContext, setExpandContext] = useState(false)
  const wordType = getWordTypeClass(wordDetails?.type)

  const handle = {
    wordClick: (word, word_status) => {
      const payload = { word, word_status, storyId: id, memberId, mastery: toUpperFirstLetter(wordDetails?.type) }
      wordClick(payload).then((res) => {
        if (res) {
          setWordDetailVisible(false)
          setActiveIndex('-1')
        }
      })
    },
  }
  return (
    <>
      <div ref={ref} className={`word_detail_modal_main_div ${visible ? 'bounce-in-right' : ' '}`}>
        {wordDetailLoader ? (
          <div className='word_detail_card_skeleton'>
            <div className='word_details_head_div_skeleton'>
              <div className='left_skeleton skeleton_animation' style={{ height: '24px' }}></div>
              <div className='right_skeleton skeleton_animation'></div>
            </div>

            <div style={{ marginBottom: '24px' }}>
              <div className='para_skeleton skeleton_animation mb-3'></div>
              <div className='para_skeleton skeleton_animation'></div>
            </div>
            <div>
              <div className='para_skeleton skeleton_animation mb-3'></div>
              <div className='para_skeleton skeleton_animation'></div>
            </div>
            <div className='divider_line'></div>
            <div>
              <div className='para_skeleton skeleton_animation mb-3'></div>
              <div className='para_skeleton skeleton_animation mb-3'></div>
              <div className='para_skeleton skeleton_animation'></div>
            </div>
          </div>
        ) : (
          wordDetails && (
            <>
              <div className='word_details_head_div'>
                <h5 className='big_word'>{wordDetails?.word}</h5>
                <p className='small_word'>
                  {wordDetails?.test_level
                    ?.map((levelDetail) => `${levelDetail?.test} ${levelDetail?.level}`)
                    .join(' / ')}
                </p>
              </div>
              <div style={{ marginBottom: '24px' }}>
                <p className='speck_text'>
                  {wordDetails?.pinyin}
                  {/* <img className='ms-2 curser_pointer' alt={'icon'} src={'/images/volume-up-icon.svg'} /> */}
                </p>
                <p className='speck_text'>{Parser(extractDefinition(wordDetails?.english))}</p>
              </div>
              <div className='tooltip_text_main_div'>
                {wordDetails?.context && (
                  <div>
                    <p className='modal_tooltip_text' onClick={() => setExpandContext(!expandContext)}>
                      Explain in context {expandContext ? upArrow : downArrow}
                    </p>
                    {expandContext && (
                      <p className={`para_show_text ${expandContext ? 'fadeincls' : ''}`}>{wordDetails?.context}</p>
                    )}
                  </div>
                )}

                <p className='modal_tooltip_text' onClick={() => setOpenExampleModal(true)}>
                  View examples
                </p>
              </div>
              <div className='divider_line'></div>
              <p className='word_detail_small_text position-relative'>
                Importance: {''}
                <span className='bold_text'>
                  {wordDetails?.importance}
                  <img
                    className='ms-2 curser_pointer'
                    style={{ position: 'relative', top: '-1px', cursor: 'pointer' }}
                    alt={'icon'}
                    src={'/images/help-circle.svg'}
                    onClick={() => setImportanceOpen(!isImportanceOpen)}
                  />
                </span>
                {isImportanceOpen ? (
                  <div className='modal_tooltip_div'>
                    <p className='tooltip_para_text'>
                      This recommendation is based on words you already know and how frequently you’ll see this word in
                      future stories. There are three levels of importance: must learn, should learn and nice to know.
                    </p>
                  </div>
                ) : null}
              </p>
              <div>
                <p className='word_detail_small_text position-relative' style={{ marginBottom: '10px' }}>
                  Mastery: {''}
                  <span className='bold_text'>
                    {toUpperFirstLetter(wordDetails?.type)}
                    <img
                      className='ms-2'
                      style={{ position: 'relative', top: '-1px', cursor: 'pointer' }}
                      alt={'icon'}
                      src={'/images/help-circle.svg'}
                      onClick={() => setMasteryOpen(!isMasteryOpen)}
                    />
                  </span>
                  {isMasteryOpen ? (
                    <div className={`modal_tooltip_div ${visible ? 'actions' : ' '}`}>
                      <p className='tooltip_para_text'>
                        We determined your mastery by how often you looked up a word in the dictionary. If this is
                        inaccurate, you can correct it manually.
                      </p>
                      <div className='tooltip_btn_div'>
                        <button
                          className='btn tooltip_btn'
                          disabled={wordDetails?.type === 'mastered'}
                          onClick={() => handle.wordClick(wordDetails?.word, 'known')}
                        >
                          Mark as mastered
                        </button>
                        <button
                          className='btn tooltip_btn'
                          disabled={wordDetails?.type === 'new'}
                          onClick={() => handle.wordClick(wordDetails?.word, 'new')}
                        >
                          Mark as new
                        </button>
                      </div>
                    </div>
                  ) : null}
                </p>
                <div className='process_box_main_div'>
                  {wordType?.map((className) => (
                    <div className={`process_box ${className}`}></div>
                  ))}
                </div>
              </div>
              <p className='word_detail_small_text mb-0'>Last seen {wordDetails?.last_seen}</p>
            </>
          )
        )}
      </div>
    </>
  )
})

export default WordDetail
