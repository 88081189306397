import React, { useEffect, useRef, useState } from 'react'
import history from '../../browserHistory'
import { connect, useSelector } from 'react-redux'
import {
  saveLastStep,
  setStep,
  translate,
  getStory,
  wordDoKnow,
  wordDoesNotKnow,
} from '../../redux/actions/storyAction'
import { getQuestion, submitAnswer, clearAnswer, getAnswer } from '../../redux/actions/questionAction'
import NavigationFooter from '../../containers/navigation-footer/NavigationFooter'
import Translate from '../../containers/popup/Translate'
import AnswerList from './answerList'
import { QUIZ, STORY_FINISHED } from 'paths'
import Loader from '../../containers/loader/loader'
import {
  characterConst,
  converterType,
  membershipNameType,
  MemberStepInStory,
  PlanChangeLink,
  storyStep,
  wordType,
} from '../../shared/constant'
import { answerValidate } from 'utils/utils'
import QuizQuestion from './QuizQuestion'
import { AddWordToHackChinese, handleAddWordToSkritter } from 'redux/actions/userActions'
import { getCurrentStreak } from 'redux/actions/streakActions'
import WithRedirect from 'containers/WithRedirect'
import { REGEX } from 'utils/regex'

const Question: React.FC = (props: any) => {
  const {
    setStep,
    storyId,
    getQuestion,
    getAnswer,
    submitAnswer,
    clearAnswer,
    lastStepEnum,
    saveLastStep,
    isGetData,
    storyLevel,
    story,
    credentials,
    membership,
    getStory,
    currentStreak,
    getCurrentStreak,
    questionDetail,
    user_auth_token,
    skritter_token,
    errors,
  } = props
  const { memberId, metaData, customFields } = credentials
  const { question, curAnswer, answers, cursorRefId, cursorTs, questionContent, count } = props.question
  const chineseWordFontSize: any = useSelector<any>(({ dashboard }) => dashboard?.chineseWordFontSize)
  const { story_submitted }: any = useSelector<any>(({ story }) => story)
  const popupRef = useRef<HTMLInputElement>(null)
  const contentStoryRef = useRef(document.createElement('div'))
  const [answer, setAnswer] = useState('')
  const [answerLoading, setAnswerLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [allowCorrection, setAllowCorrection] = useState(true)
  const [answerError, setAnswerError] = useState('')
  const [transVisible, setTransVisible] = useState(false)
  const [transData, setTransData] = useState({})
  const [questionWord, setQuestionWord] = useState<any>(questionContent ? [...questionContent] : [])
  const [activeIndex, setActiveIndex] = useState('-1')

  const isFree = membership?.name == membershipNameType.Free
  const isPremium = membership?.name == membershipNameType.Premium
  const isStandard = membership?.name == membershipNameType.Standard
  const isSchool = membership?.name == membershipNameType.School
  const isPro = membership?.name == membershipNameType.Pro
  const isTeacher = membership?.name == membershipNameType.Teacher

  const onTextChange = (e) => {
    setAnswer(e.target.value)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    // clearAnswer()
    setStep(storyStep.Step3)
  }, [])

  const onSubmitAnswer = async () => {
    const answerHasError = await answerValidate(answer)
    setAnswerError(answerHasError?.error)
    if (answerHasError?.error.length) return

    const memberName = (customFields?.['first-name'] && metaData?.firstName) || 'name'
    if (!isSubmitting) {
      setIsSubmitting(true)
      const trimmedAnswer = answer.replace(/[\n\r]/g, '')
      const payload = {
        memberId,
        memberName,
        storyId,
        answer: trimmedAnswer,
        correctionAllowedByTeacher:
          isFree || isStandard
            ? false
            : story_submitted?.record_exist && story_submitted?.storyId
            ? false
            : allowCorrection,
        level: storyLevel,
      }
      submitAnswer(payload)
        .then(() => {
          clearAnswer()
          getMoreAnswer(storyId, storyLevel, memberId, null, null, null)
          setIsSubmitting(false)
        })
        .catch(() => {
          setIsSubmitting(false)
        })
    }
  }

  const onFinishClick = () => {
    if (curAnswer && history) {
      history.push(STORY_FINISHED)
    }
  }
  useEffect(() => {
    if (JSON.stringify(story) === '{}') {
      getStory(storyId, storyLevel, memberId)
    }
  }, [isGetData, storyId, storyLevel, memberId])

  useEffect(() => {
    if (!currentStreak && memberId) {
      getCurrentStreak(memberId)
    }
  }, [currentStreak, memberId])

  const redirectToAccount = () => {
    // const PlanName = accountTab.Plan
    // history.push(ACCOUNT, PlanName)
    // window.open('https://www.maayot.com/plan-selection', '_blank')
    window.open(PlanChangeLink)
  }

  useEffect(() => {
    if (storyId && storyLevel && memberId) {
      if (lastStepEnum !== null && lastStepEnum < storyStep.Step3) {
        saveLastStep(MemberStepInStory.QUESTION, storyLevel, memberId, storyId)
      }
      if (!props?.question?.question) {
        _getQuestion(storyId, storyLevel, memberId)
      }
    }
  }, [storyId, storyLevel, memberId, isSubmitting])

  const _getQuestion = (storyID, storyLevel, memberId) => {
    setAnswerLoading(true)
    getQuestion(storyID, storyLevel, memberId)
      .then((result) => {
        setQuestionWord([...result?.questionContent])
        setAnswerLoading(false)
      })
      .catch(() => {
        setAnswerLoading(false)
      })
  }
  const getMoreAnswer = (storyID, storyLevel, memberId, refId, refTs, perPage) => {
    setAnswerLoading(true)
    getAnswer(storyID, storyLevel, memberId, refId, refTs, perPage)
      .then(() => {
        setAnswerLoading(false)
      })
      .catch(() => {
        setAnswerLoading(false)
      })
  }

  const onClose = () => {
    // onClose()
    console.log('closed click')
  }

  useEffect(() => {
    document.addEventListener('scroll', onTranslateClose)
    document.addEventListener('click', onDocumentClick)
    return () => {
      document.removeEventListener('scroll', onTranslateClose)
      document.removeEventListener('click', onDocumentClick)
    }
  }, [contentStoryRef, questionDetail])

  const setTranslate = (word: string, posTop: number, posLeft: number, data: any) => {
    setTransVisible(true)
    const newWord1 = !story?.newWord1
      ? ''
      : globalThis.characterPreference === characterConst.traditional
      ? converterType.converterTraditional(story?.newWord1)
      : converterType.converterSimplified(story?.newWord1)
    const newWord2 = !story?.newWord2
      ? ''
      : globalThis.characterPreference === characterConst.traditional
      ? converterType.converterTraditional(story?.newWord2)
      : converterType.converterSimplified(story?.newWord2)
    const isOldWord = story?.oldWords?.includes(word)

    const type =
      newWord1 === word || newWord2 === word ? wordType.NewWord : isOldWord ? wordType.OldWord : wordType.Word
    setTransData({
      type,
      top: posTop,
      left: posLeft,
      word,
      definition: data.pinyin,
      example1: data?.english,
      example2: '',
      level: 'HSK 5',
    })
  }

  const handleTranslate = (event) => {
    if (
      (event.type === 'mouseover' || event.type === 'click') &&
      event.target.innerText &&
      event.target.innerText.length > 0
    ) {
      trans(event.target.innerText, event.clientY, event.clientX)
    }
  }

  const trans = (word: string, posTop: number, posLeft: number) => {
    const questionDetailInfo =
      answers.length > 0
        ? [...questionContent, ...answers[answers?.length - 1]?.allDataOpContent]
        : [...questionContent]
    if (/^[&.-”“，、；？@!#$%^&*()]+$/.test(word) || word.trim() === '。') {
      setTransVisible(false)
      return
    }
    const wordTranslate = questionDetailInfo?.filter(
      (iword) =>
        (globalThis.characterPreference === characterConst.traditional
          ? converterType.converterTraditional(iword.word)
          : converterType.converterSimplified(iword.word)) === word,
    )

    if (wordTranslate && wordTranslate.length > 0) {
      setTranslate(word, posTop, posLeft, wordTranslate[0])
      if (user_auth_token) {
        AddWordToHackChinese(wordTranslate[0], user_auth_token)
      }
      if (skritter_token) {
        handleAddWordToSkritter({
          word,
          translate: wordTranslate[0],
          title: story?.storyTitleContent,
          skritter_token: skritter_token,
        })
      }
    } else {
      translate(word)
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          if (data && data.length > 0) {
            setTranslate(word, posTop, posLeft, data[0])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const onTranslateClose = () => {
    setTransVisible(false)
  }
  const onDocumentClick = (e) => {
    if (!popupRef?.current?.contains(e.target)) {
      setTransVisible(false)
    }
  }

  const handleClickWord = (event, word, key) => {
    handleTranslate(event)
    if (typeof word !== 'string' && !REGEX.specialChars.test(word?.word)) {
      const quizWords = questionWord
        .filter?.((wordInfo) => {
          if (wordInfo?.word.includes('<p>') || wordInfo?.word.includes('</p>') || wordInfo?.word.includes('</p><p>')) {
          } else {
            return wordInfo
          }
        })
        ?.filter((wordInfo: any) => wordInfo?.word !== word?.word)
      setQuestionWord(quizWords)
      wordDoesNotKnow({ word: word?.word, memberId, storyId })
    }
    setActiveIndex(activeIndex === key ? `-1` : key)
    event.preventDefault()
    event.stopPropagation()
  }

  const onNext = () => {
    const uniqueItems = {}
    questionWord.forEach((item) => {
      uniqueItems[item.word] = item
    })
    const wordList = Object.values(uniqueItems)
      ?.filter((wordInfo: any) => !REGEX.specialChars.test(wordInfo?.word))
      ?.map((wordInfo: any) => wordInfo?.word)
    wordDoKnow({ wordList, storyId, memberId })
    history.push(STORY_FINISHED)
  }

  return (
    <>
      <div className='section-2'>
        <div className='section-story w-container'>
          <div className='w-row'>
            <div className='w-col w-col-6 content-left'>
              <span className='introduction-title'>Writing</span>
            </div>
          </div>
          {((errors === 'Error' || errors === null) && question && (
            <>
              <div className='w-nav story-nav-content'></div>
              <div className='w-row section-story-content'>
                <div className='w-col w-col-8 content-left'>
                  <div className='session-title'>
                    <QuizQuestion
                      questionDetail={questionContent}
                      showPinYin={false}
                      story={story}
                      handleClickWord={handleClickWord}
                      activeIndex={activeIndex}
                      chineseWordFontSize={chineseWordFontSize}
                    />
                  </div>
                  <div className={'question-textarea'}>
                    {curAnswer ? (
                      <div className='question-view-answer m-b-gray-4'>
                        <p className={'m-gray-2 m-sm-text'}>Your answer</p>
                        {curAnswer.split('\n').map((item, key) => (
                          <span
                            style={{
                              fontSize: chineseWordFontSize?.newWordsExample,
                              lineHeight: '1.5',
                              letterSpacing: '1.5px',
                            }}
                            key={key}
                          >
                            {item}
                            <br />
                          </span>
                        ))}
                      </div>
                    ) : (
                      <>
                        <textarea
                          placeholder='Your answer in Chinese Language....'
                          value={answer}
                          onChange={onTextChange}
                          className={answerError !== '' ? 'input-error' : ''}
                        />
                        {answerError !== '' && (
                          <div className='error'>
                            <i className='fa fa-exclamation-circle' /> {answerError}
                          </div>
                        )}
                        <div className='w-d-flex m-sp-bw align-center mt-10'>
                          {isPro || isTeacher ? (
                            !story_submitted?.storyId ? (
                              <div
                                className='allow-answer m-flx align-center checkbox'
                                onClick={() => setAllowCorrection(!allowCorrection)}
                              >
                                <input type='checkbox' checked={allowCorrection} />
                                <span className='checkmark'></span>
                                {/* <div className='checkbox'> </div> */}
                                <span className='pl-5'>Allow teacher to correct this answer</span>
                              </div>
                            ) : story_submitted?.storyId === storyId ? (
                              <div
                                className='allow-answer m-flx align-center checkbox'
                                onClick={() => setAllowCorrection(!allowCorrection)}
                              >
                                <input type='checkbox' checked={allowCorrection} />
                                <span className='checkmark'></span>
                                {/* <div className='checkbox'> </div> */}
                                <span className='pl-5'>Allow teacher to correct this answer</span>
                              </div>
                            ) : null
                          ) : (
                            // ''
                            <div
                              className='allow-answer m-flx align-center checkbox'
                              // onClick={() => redirectToAccount()}
                              // style={{ opacity: 0.5 }}
                            >
                              <input type='checkbox' />
                              {/* <span className='checkmark-disable'  title='Get your writing and grammar corrected daily by a teacher. <a>Only for Pro. Upgrade now</a>'> */}
                              <span className='checkmark-disable' tabIndex={1}>
                                <div className='tooltiptextWriter' id='tooltiptextWriter'>
                                  <button className='tooltiptext-close' onClick={onClose}>
                                    <i className='fa fa-times' aria-hidden='true'></i>
                                  </button>
                                  Get your writing and grammar corrected daily by a teacher. Only for Pro.{' '}
                                  <a
                                    onClick={redirectToAccount}
                                    style={{ textDecoration: 'underline' }}
                                    // className={'m-gray-2 m-pointer m-h6 m-link'}
                                    // href='https://www.maayot.com/plan-selection'
                                    // target='_blank'
                                  >
                                    Upgrade now
                                  </a>
                                </div>
                              </span>
                              <span className='pl-5'>Allow teacher to correct this answer</span>
                            </div>
                          )}
                          {answer && (
                            <div className='question-button-answer'>
                              <div className='disable-correction-container new-tooltip' tabIndex={1}>
                                <button className='button-2 w-button green-btn-class' onClick={onSubmitAnswer}>
                                  {!isSubmitting ? 'Submit Answer' : <Loader showText={false} />}
                                </button>
                                {/* {is_story_submitted && (
                                  <div className='disable-correction-tooltip Corrections-tooltip new-tooltip-conation'>
                                    <div className='tooltiptextWriter' id='tooltiptextWriter'>
                                      <button className='tooltiptext-close'>
                                        <i className='fa fa-times' aria-hidden='true'></i>
                                      </button>
                                      As a pro user, You are only allow to submit 1 story per day.
                                    </div>
                                  </div>
                                )} */}
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className='w-col w-col-4 content-new-word'>
                  <div className='w-row'>
                    {answers?.length === 0 ? (
                      <div className='not_found_label'>Not Found</div>
                    ) : !isSubmitting && answers?.length !== 0 ? (
                      <AnswerList
                        handleClickWord={handleClickWord}
                        activeIndex={activeIndex}
                        answerLoading={answerLoading}
                        answers={answers}
                        story={story}
                        hasMore={count !== answers?.length ? true : false}
                        moreClick={() =>
                          getMoreAnswer(storyId, storyLevel, memberId, cursorRefId, cursorTs, answers?.length + 5)
                        }
                        chineseWordFontSize={chineseWordFontSize}
                      />
                    ) : (
                      <Loader showText={false} />
                    )}
                  </div>
                </div>
              </div>
            </>
          )) || <Loader />}
        </div>
        <Translate ref={popupRef} visible={transVisible} data={transData} onClose={onTranslateClose} />
      </div>
      <NavigationFooter
        className={'right-active'}
        backPath={`${QUIZ}/${storyId}`}
        backText={'Back'}
        nextText={'Finish'}
        nextPath={STORY_FINISHED}
        nextOnclick={onNext}
        // nextOnclick={onFinishClick}
      />
    </>
  )
}

const mapStateToProps = (state: any, ownProps: any) => ({
  credentials: state.user.credentials,
  user_auth_token: state.user.user_auth_token,
  skritter_token: state.user.skritter_token,
  membership: state.user.membership,
  question: state.question,
  errors: state.UI.errors,
  storyId: ownProps.match.params.id,
  story: state.story.currentStory,
  storyLevel: state.story.storyLevel,
  lastStepEnum: state.story.lastStepEnum,
  isGetData:
    (state.user.credentials.level.length > 0 || state.user.credentials.customFields?.level.length > 0) &&
    !state.story.errors &&
    (JSON.stringify(state.story.currentStory) === '{}' || state.story.currentStory.id !== ownProps.match.params.id),
  currentStreak: state.streak.current,
})
const mapActionsToProps = {
  getQuestion,
  getAnswer,
  submitAnswer,
  clearAnswer,
  setStep,
  saveLastStep,
  getStory,
  //
  getCurrentStreak,
}
export default connect(mapStateToProps, mapActionsToProps)(WithRedirect(Question))
